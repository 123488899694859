import { Expression } from 'mapbox-gl';
import { useEffect } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';
import getPinURL from '~/src/common/helpers/getPinURL';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import {
  defaultIconSize,
  defaultZoomStops,
  exponentialIconRatio,
} from '../../../dynamic-map/components/POISource/POIHelpers';
import useLayerIds from '../../../dynamic-map/hooks/useLayerIds';
import useMapContext from '../../../dynamic-map/hooks/useMapContext';
import useMapImage from '../../../dynamic-map/hooks/useMapImage';
import usePricingDataStore from '../../../dynamic-map/hooks/usePricingDataStore';

interface OfferingItem {
  category: { Value: string };
  sub_category?: { Value: string };
  item_group?: { Value: string };
  predicted_price?: { Value: number };
  competitor_item_id?: { Value: string };
  [key: string]: { Value: string | number } | undefined;
}

export interface FormattedPriceMatchingData {
  competitor: string;
  address: string;
  cbsa: string;
  coordinates: string;
  offerings: Array<{
    category: string;
    name: string;
    competitor_price: number;
    price: number | null;
    competitorSku: string | null;
  }>;
  popupCoordinates: {
    longitude: number;
    latitude: number;
  };
}

interface PricingSourceProps {
  sourceId?: string;
  layerId?: string;
  color?: string;
  data: GeoJSON.FeatureCollection;
  showLabels?: boolean;
  onPricingClick?: (location: FormattedPriceMatchingData) => void;
}

interface Competitor {
  id: string;
  name: string;
}

export const extractUniqueCompetitors = (
  data: GeoJSON.FeatureCollection
): Competitor[] => {
  const competitorsMap = new Map<string, string>();

  data.features.forEach((feature) => {
    if (feature.properties?.competitor) {
      const competitor = feature.properties.competitor;
      competitorsMap.set(competitor, competitor);
    }
  });

  return Array.from(competitorsMap.entries())
    .map(([id, name]) => ({
      id,
      name: name.charAt(0).toUpperCase() + name.slice(1),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

const PulsePriceMatchingSource: React.FC<PricingSourceProps> = ({
  sourceId = 'pricing-source',
  layerId = 'pricing-layer',
  color = '#FF4B4B',
  data,
  showLabels,
  onPricingClick,
}: PricingSourceProps) => {
  const map = useMapContext();
  const roadsLayerId = useLayerIds((layer) => layer.id === 'roads-layer')[0];
  const { userSettings } = useSettingsStore();
  const client = userSettings?.client;
  const selectedBrands = usePricingDataStore((state) => state.selectedBrands);

  const pinURL = getPinURL({
    color,
    background: '#FFFFFF',
  });

  const isPinReady = useMapImage({
    id: layerId,
    src: pinURL,
  });

  useEffect(() => {
    if (!map || !userSettings) return;

    const handleClick = (event: MapLayerMouseEvent) => {
      event.preventDefault();
      const feature = event.features?.[0];
      if (feature?.geometry?.type === 'Point' && feature.properties) {
        const parsedData = feature.properties.data
          ? JSON.parse(feature.properties.data)
          : [];

        const groupedOfferings = parsedData.reduce(
          (
            acc: FormattedPriceMatchingData['offerings'],
            item: OfferingItem
          ) => {
            const category = item.category?.Value || 'Uncategorized';
            const name =
              item.sub_category?.Value || item.item_group?.Value || '';
            const clientPriceKey = `${client}_price`;

            return [
              ...acc,
              {
                name,
                category,
                competitor_price: item.competitor_price?.Value || 0,
                price: item[clientPriceKey]?.Value || null,
                competitorSku: item.competitor_item_id?.Value || null,
              },
            ];
          },
          []
        );

        const sortedOfferings = groupedOfferings.sort(
          (
            a: FormattedPriceMatchingData['offerings'][0],
            b: FormattedPriceMatchingData['offerings'][0]
          ) => a.category.localeCompare(b.category)
        );

        const formattedLocation: FormattedPriceMatchingData = {
          competitor: feature.properties?.competitor || '',
          address: feature.properties?.address || '',
          cbsa: feature.properties?.cbsa || '',
          coordinates: `${feature.geometry.coordinates[1]}, ${feature.geometry.coordinates[0]}`,
          offerings: sortedOfferings,
          popupCoordinates: {
            longitude: event.lngLat.lng,
            latitude: event.lngLat.lat,
          },
        };
        onPricingClick?.(formattedLocation);
      }
    };

    map.on('click', layerId, handleClick);
    return () => {
      map.off('click', layerId, handleClick);
    };
  }, [map, layerId, onPricingClick]);

  const filteredData = {
    ...data,
    features: data.features.filter((feature) => {
      if (selectedBrands.length === 0) return true;
      return selectedBrands.includes(feature.properties?.competitor);
    }),
  };

  return (
    <Source id={sourceId} type="geojson" data={filteredData}>
      <Layer
        id={layerId}
        type="symbol"
        layout={{
          ...(isPinReady ? { 'icon-image': layerId } : {}),
          'icon-anchor': 'bottom',
          'icon-allow-overlap': true,
          'icon-size': {
            type: 'exponential',
            stops: [
              [defaultZoomStops[0], defaultIconSize * exponentialIconRatio],
              [defaultZoomStops[1], defaultIconSize],
            ],
          },
          ...(showLabels && {
            'text-field': ['get', 'competitor'] as Expression,
            'text-offset': [0, 0.5],
            'text-anchor': 'top',
            'text-size': 15,
            'text-allow-overlap': true,
          }),
        }}
        beforeId={roadsLayerId}
      />
    </Source>
  );
};

export default PulsePriceMatchingSource;
