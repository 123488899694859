import { parseEnv } from '@plotr/common-utils/src';
import * as Sentry from '@sentry/react';

import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

enum Environments {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

const env = parseEnv({
  SENTRY_DNS: process.env.SENTRY_DNS,
  NODE_ENV: process.env.NODE_ENV,
});

const environment = (
  [Environments.LOCAL, Environments.DEVELOPMENT] as string[]
).includes(env.NODE_ENV)
  ? Environments.DEVELOPMENT
  : env.NODE_ENV === Environments.STAGING
    ? Environments.STAGING
    : env.NODE_ENV === Environments.PRODUCTION
      ? Environments.PRODUCTION
      : null;

function initSentry() {
  if (!environment || environment !== Environments.PRODUCTION) return;

  Sentry.init({
    environment,
    dsn: env.SENTRY_DNS,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.replayCanvasIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/plotr\.com/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

initSentry();
