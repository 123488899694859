import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  Typography,
  styled,
} from '@mui/material';
import DarkMapThumbnail from '~/src/assets/images/map-styles/mapbox_dark.png';
import LightMapThumbnail from '~/src/assets/images/map-styles/mapbox_light.png';
import SatteliteMapThumbnail from '~/src/assets/images/map-styles/mapbox_satellite.png';
import StreetMapThumbnail from '~/src/assets/images/map-styles/mapbox_streets.png';
import ContentPopover from '~/src/common/components/ContentPopover';
import useDynamicMapStore, {
  MapStyle,
} from '~/src/features/dynamic-map/hooks/useDynamicMapStore';

const baseMapConfig: Record<MapStyle, { label: string; thumbnail: string }> = {
  [MapStyle.Streets]: {
    label: 'Streets',
    thumbnail: StreetMapThumbnail,
  },
  [MapStyle.Satellite]: {
    label: 'Satellite',
    thumbnail: SatteliteMapThumbnail,
  },
  [MapStyle.Light]: {
    label: 'Light',
    thumbnail: LightMapThumbnail,
  },
  [MapStyle.Dark]: {
    label: 'Dark',
    thumbnail: DarkMapThumbnail,
  },
};

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  overflow: 'hidden',
  borderWidth: '3px !important',
  borderColor: `transparent`, // Transparent border for unselected state
  padding: 0,
  borderRadius: `${theme.spacing(1.5)} !important`,
  maxWidth: '80px',
  minHeight: '100px',
  transition: '0.3s',
  '&.Mui-selected': {
    pointerEvents: 'none',
    backgroundColor: 'transparent',
    borderColor: theme.palette.secondary.main, // Highlight with a border when selected
  },
  '& img': {
    transition: theme.transitions.create('opacity'),
    opacity: 1,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  '&:hover': {
    transition: '0.3s',
    borderColor: '#ddd', // Highlight with a border when hovered
  },
}));

interface MapStyleToggleProps {
  src: string;
  value: MapStyle;
}

const MapStyleToggle = ({
  src,
  value,
  ...rest
}: MapStyleToggleProps & ToggleButtonProps) => {
  return (
    <StyledToggleButton value={value} {...rest}>
      <Box overflow="hidden" position="relative" height="100%">
        <img
          src={src}
          alt={baseMapConfig[value].label}
          title={baseMapConfig[value].label}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            p: 0.5,
            left: 0,
            right: 0,
            bottom: 0,
            color: 'white',
            textAlign: 'center',
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Typography variant="caption">
            {baseMapConfig[value].label}
          </Typography>
        </Box>
      </Box>
    </StyledToggleButton>
  );
};

const BaseMap = () => {
  const selectedMapStyle = useDynamicMapStore((state) => state.mapStyle);
  const setMapStyle = useDynamicMapStore((state) => state.setMapStyle);

  return (
    <ContentPopover
      parentContainerClass="base-map-popover"
      triggerElement={
        <Box>
          <img src={baseMapConfig[selectedMapStyle]?.thumbnail} />
          <Typography variant="caption">
            {baseMapConfig[selectedMapStyle]?.label}
          </Typography>
        </Box>
      }
    >
      <Box padding={2} bgcolor="#fff" maxWidth="370px" minWidth="370px">
        <Typography variant="h6" marginBottom={1.5}>
          Base Map
        </Typography>
        <ToggleButtonGroup
          value={selectedMapStyle}
          onChange={(_, value: MapStyle) => setMapStyle(value)}
          exclusive
          fullWidth
          sx={{ justifyContent: 'space-between' }}
        >
          {Object.keys(baseMapConfig).map((key) => {
            const mapStyle = key as MapStyle;
            return (
              <MapStyleToggle
                key={mapStyle}
                value={mapStyle}
                src={baseMapConfig[mapStyle].thumbnail}
              />
            );
          })}
        </ToggleButtonGroup>
      </Box>
    </ContentPopover>
  );
};
export default BaseMap;
