export const Color = {
  transparent: 'rgba(0, 0, 0, 0)',
  white: '#ffffff',
  blue: '#0000FF',
  yellow: '#eef91a',
  yellowOrange: '#f9d116',
  orange: '#fcac03',
  darkOrange: '#fc8003',
  red: '#ff0000',
  purple: '#a020f0',
  darkPurple: '#4b0082',
  lightBlue: '#80c3e6',
  darkBlue: '#0303a3',
  lightGreen: '#a6d96a',
  darkGreen: '#1a9641',
  lightGray: '#d8daeb',
  darkGray: '#a6a6a6',
  lightRed: '#e34a33',
  darkRed: '#b30000',
  lightYellow: '#fdae61',
  darkYellow: '#ff0000',

  brown: '#8B4513',
  black: '#000000',
  teal: '#008080',
  gray: '#808080',
  green: '#008000',

  urbanCore: '#FF0000',
  urban: '#FFA500',
  metropolitan: '#FFD700',
  suburban: '#FFFFE0',
  inTown: '#98FB98',
  rural: '#008000',
};
