import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';
import type { POIGroupRequest } from '../../features/dynamic-map/services/poiService';

const env = parseEnv({ PLOTR_API: process.env.PLOTR_API });

export interface EnterpriseLayerConfig {
  retailProximityHeatmap?: {
    mapboxURLs: string[];
    minWomThreshold?: number;
  };
  blockGroupMatches?: {
    apiPath: string;
  };
}

export type BenchmarkIdentifier = {
  type: 'tag' | 'kvp';
  key?: string;
  value: string;
};

export type BrandConfig = {
  display: string;
  searchQuery?: Record<string, string>;
  benchmarkIdentifier?: BenchmarkIdentifier;
  settings: {
    tam: {
      male: {
        ageRanges: [number, number][];
      };
      female?: {
        ageRanges: [number, number][];
      };
    };
  };
};

export interface UserSettings {
  userId: string;
  brandConfigs?: Record<string, BrandConfig>;
  poiGroups?: POIGroupRequest[];
  enterpriseLayerConfig?: EnterpriseLayerConfig;
  clientZonesAPIPath?: string;
  userGeoAPIPath?: string;
  customerLayers?: string[];
  assignedRoomId?: string;
  featureFlags?: Record<string, boolean>;
  client?: string;
  orgSiteURL?: string;
  reportTemplate?: string;
}

export async function fetchUserSettings(
  accessToken: string
): Promise<UserSettings | null> {
  const res = await axios.get(`${env.PLOTR_API}/settings`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (res.status === 200) {
    return res.data as UserSettings;
  } else {
    console.error('Error fetching user settings: ', res.statusText);
    return null;
  }
}
