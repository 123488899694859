import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useBlockGroupsStore from '~/src/features/dynamic-map/hooks/useBlockGroupStore';
import useTrafficStore from '~/src/features/dynamic-map/hooks/useTrafficStore';
import { useSubscriptionDialog } from '~/src/global/components/SubscriptionDialog';
import { customerLayers } from '~/src/global/constants/customerLayersConfig';
import { getEnterpriseLayers } from '~/src/global/constants/enterpriseLayersConfig';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import { checkPermission } from '~/src/global/services/permissionService';
import { GroupNameType, LayerConfig } from '.';
import useLayersStore, {
  LayerCard,
} from '../../../dynamic-map/hooks/useLayersStore';
import getMapLayerDefinitions from '../../data/getMapLayerDefinitions';
import EditLayerModal from './components/EditLayerModal/EditLayerModal';
import LayerImageCategories from './components/EditLayerModal/LayerImageCategories';
import SingleLayerCard from './components/SingleLayerCard';

interface LayersDisplayStatusProps {
  showSpinner: boolean;
  textLabel: string;
}

interface LayersToRenderState {
  classified: Record<string, LayerCard[]>;
  nonClassified: LayerCard[];
}

interface MapLayersMenuProps {
  layersGroup: GroupNameType;
}

const LayersDisplayStatus = ({
  showSpinner,
  textLabel,
}: LayersDisplayStatusProps) => (
  <Box
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    marginTop={2}
  >
    {showSpinner && (
      <CircularProgress color="primary" size={18} sx={{ marginRight: 1 }} />
    )}
    <Typography variant="body1" color="#666">
      {textLabel}
    </Typography>
  </Box>
);

const MapLayersMenu = ({ layersGroup }: MapLayersMenuProps) => {
  // TODO: Enable extra base layers for enterprise when zones fetch new style ordering layer.

  // Access the map instance
  const layers = useLayersStore((state) => state.layers);
  const setLayers = useLayersStore((state) => state.setLayers);

  const [selectedLayer, setSelectedLayer] = useState<LayerCard | null>(null);
  const [layersToRender, setLayersToRender] = useState<LayersToRenderState>({
    classified: {},
    nonClassified: [],
  });
  const { permissions, setPermission } = usePermissionsStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);

  const { open: openSubscriptionDialog } = useSubscriptionDialog();

  const handleAccordionSelect =
    (selected: number) => (event: React.SyntheticEvent, isExpanded: boolean) =>
      setActiveAccordion(isExpanded ? selected : null);

  const { accessToken } = useAccessToken();
  const blockGroups = useBlockGroupsStore((state) => state.blockGroups);

  const enabledCustomerLayers = useSettingsStore(
    (state) => state.userSettings?.customerLayers ?? []
  );
  const userSettings = useSettingsStore((state) => state.userSettings);

  const blockGroupAPIPath =
    userSettings?.enterpriseLayerConfig?.blockGroupMatches?.apiPath;

  const heatmapMapboxURLs =
    userSettings?.enterpriseLayerConfig?.retailProximityHeatmap?.mapboxURLs;

  const layerInfo = useTrafficStore((state) => state.layerInfo);

  const trafficLayerIds = layerInfo
    .filter((layer) => layer?.fields && Object.keys(layer.fields).length > 0)
    .map((layer) => `traffic_volume-${layer.id}`);

  const BlockGroupBaseLayerID = 'block_groups';
  const blockGroupMatchLayerIds: string[] = useMemo(
    () => [
      BlockGroupBaseLayerID,
      ...blockGroups.map((blockGroupSet) => `BG-${blockGroupSet.groupName}`),
    ],
    [blockGroups]
  );

  const enterpriseLayers: Record<string, LayerConfig> = useMemo(() => {
    const heatmapLayerIds: string[] = ['zones', 'zone-numbers'];

    const enterpriseConfig = getEnterpriseLayers(
      heatmapLayerIds,
      blockGroupMatchLayerIds
    );

    if (!blockGroupAPIPath) {
      delete enterpriseConfig.block_group_match;
    }

    if (!heatmapMapboxURLs) {
      delete enterpriseConfig.retail_proximity_heatmap;
    }

    return enterpriseConfig;
  }, [blockGroupAPIPath, blockGroupMatchLayerIds, heatmapMapboxURLs]);

  const insights: {
    [key: string]: LayerConfig;
  } = useMemo(
    () => ({
      ...Object.entries(customerLayers).reduce((acc, [key, value]) => {
        const isEnabled = enabledCustomerLayers.some((enabledLayer) => {
          if (enabledLayer.endsWith('*')) {
            return key.startsWith(enabledLayer.slice(0, -1));
          }
          return enabledLayer === key;
        });
        return isEnabled ? { ...acc, [key]: value } : acc;
      }, {}),
      ...getMapLayerDefinitions(trafficLayerIds),
    }),

    [trafficLayerIds, enabledCustomerLayers]
  );

  const options: { [key: string]: LayerConfig } = useMemo(() => {
    return {
      ...insights,
      ...enterpriseLayers,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const layerDetailBuilder = useCallback(
    ({ key, value }: { key: string; value: LayerConfig }): LayerCard => {
      const { permission, category } = value;
      const isLocked = permission ? !permissions[permission] : false;

      return {
        ...value,
        id: key,
        insight: key,
        layerGroup: { layerIds: [key] },
        opacity: 0,
        imageUrl: LayerImageCategories[category],
        isLocked,
      };
    },
    [permissions]
  );

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const fetchPermissions = async () => {
      // Explicitly typing the Set elements as string
      const permissionKeys = new Set<string>();

      // Extract permissions from insights and enterpriseLayers
      Object.values(options).forEach((item) => {
        if (item.permission) permissionKeys.add(item.permission);
      });

      for (const key of permissionKeys) {
        await checkPermission(accessToken, key, setPermission);
      }

      setLoading(false);
    };
    fetchPermissions();
  }, [accessToken, options, setPermission]);

  useEffect(() => {
    if (loading) return;

    const classifiedLayers: Record<string, LayerCard[]> = {};
    const nonClassifiedLayers: LayerCard[] = [];
    const mergeGroups: Record<string, LayerCard[]> = {};

    Object.entries(options).forEach(([key, value]) => {
      const { groupName, mergeGroup, class: layerClass } = value;
      if (groupName !== layersGroup) return;

      const layer = layerDetailBuilder({ key, value });

      if (mergeGroup) {
        if (!mergeGroups[mergeGroup]) {
          mergeGroups[mergeGroup] = [];
        }
        mergeGroups[mergeGroup].push(layer);
      } else {
        const upperLayerClass = layerClass?.toUpperCase();
        if (upperLayerClass) {
          classifiedLayers[upperLayerClass] ??= [];
          classifiedLayers[upperLayerClass].push(layer);
        } else {
          nonClassifiedLayers.push(layer);
        }
      }
    });

    // Process merge groups
    Object.entries(mergeGroups).forEach(([groupName, layers]) => {
      const mergedLayer: LayerCard = {
        ...layers[0],
        id: groupName,
        displayName: groupName,
        layersList: layers,
      };

      const layerClass = mergedLayer.class?.toUpperCase();
      if (layerClass) {
        classifiedLayers[layerClass] ??= [];
        classifiedLayers[layerClass].push(mergedLayer);
      } else {
        nonClassifiedLayers.push(mergedLayer);
      }
    });

    setLayersToRender({
      classified: classifiedLayers,
      nonClassified: nonClassifiedLayers,
    });
  }, [loading, layersGroup, options, layerDetailBuilder]);

  const hasLayerAccess: (layer: LayerCard) => boolean = (layer) => {
    // Open the subscription modal for locked layers
    if (layer.permission && !permissions?.[layer.permission]) {
      setSelectedLayer(null);
      openSubscriptionDialog();

      return false;
    }

    return true;
  };

  const handleLayerSelect = (targetLayer: LayerCard) => {
    if (!hasLayerAccess(targetLayer)) return;

    setSelectedLayer(targetLayer);
  };

  const handleEditLayerClose = () => setSelectedLayer(null);

  const handleSaveLayer = (targetLayer: LayerCard) => {
    if (!hasLayerAccess(targetLayer)) return false;

    const savedLayersCopy: LayerCard[] = [...layers];
    const doLayerExist: number = savedLayersCopy.findIndex(
      (layer) => layer.id === selectedLayer?.id
    );
    if (doLayerExist > -1) savedLayersCopy[doLayerExist] = targetLayer;
    else savedLayersCopy.unshift(targetLayer);

    setLayers(savedLayersCopy);

    // Reset selected layer and close dialog
    setSelectedLayer(null);
    return true;
  };

  const sortLayers = (a: LayerCard, b: LayerCard) => {
    const nameA = a.displayName || '';
    const nameB = b.displayName || '';
    return nameA.localeCompare(nameB);
  };

  return (
    <>
      <EditLayerModal
        selectedLayer={selectedLayer}
        onClose={handleEditLayerClose}
        onSave={handleSaveLayer}
      />
      <Box className="custom-scrollbar">
        {!loading ? (
          <>
            {Object.keys(layersToRender.classified).length ||
            layersToRender.nonClassified.length ? (
              <>
                {Object.entries(layersToRender.classified)
                  .sort(([a], [b]) => a.localeCompare(b))
                  .map(([title, layers], index) => {
                    return (
                      <Accordion
                        key={title}
                        expanded={activeAccordion === index}
                        onChange={handleAccordionSelect(index)}
                        sx={{ marginTop: 1, boxShadow: 'none' }}
                        className="layers-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          sx={{
                            border: '1px solid #ddd',
                            borderRadius: '4px 4px 0 0',
                            backgroundColor: '#00000008',
                          }}
                        >
                          <Typography fontWeight={500}>{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            border: '1px solid #ddd',
                            borderRadius: '0 0 4px 4px',
                            borderTop: 0,
                          }}
                        >
                          {layers.sort(sortLayers).map((layer: LayerCard) => (
                            <SingleLayerCard
                              key={layer.id}
                              layer={layer}
                              onSave={handleSaveLayer}
                              onEdit={handleLayerSelect}
                            />
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                {layersToRender.nonClassified
                  .sort(sortLayers)
                  .map((layer: LayerCard) => (
                    <SingleLayerCard
                      key={layer.id}
                      layer={layer}
                      onSave={handleSaveLayer}
                      onEdit={handleLayerSelect}
                    />
                  ))}
              </>
            ) : (
              <LayersDisplayStatus
                showSpinner={false}
                textLabel="No layers in this group"
              />
            )}
          </>
        ) : (
          <LayersDisplayStatus
            showSpinner={true}
            textLabel="Fetching layers..."
          />
        )}
      </Box>
    </>
  );
};

export default MapLayersMenu;
