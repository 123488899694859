import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Popup } from 'react-map-gl';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import theme from '~/src/theme';
import { formatDateRange, formatTierRange } from '../../utils/formatters';
import { FormattedFranchiseData } from './PulsePriceFranchisesSource';

interface FranchisePricingPopupProps {
  data: FormattedFranchiseData;
  onClose: () => void;
}

const formatCurrency = (value: number | null) => {
  if (value === null) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(Math.round(value));
};

const formatPercentage = (value: number) => {
  return `${(value * 100).toFixed(1)}%`;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`franchise-tabpanel-${index}`}
      aria-labelledby={`franchise-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1.5 }}>{children}</Box>}
    </div>
  );
}

const FranchisePricingPopup = ({
  data,
  onClose,
}: FranchisePricingPopupProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    false
  );

  const map = useMapContext();

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleAccordionChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  const formatValue = (value: string | number | null | undefined) => {
    if (value === null || value === undefined || value === '' || value === 0) {
      return '-';
    }
    return value;
  };

  const renderLocationInfo = () => {
    const locationFields = [
      { label: 'Market (CBSA)', value: data.cbsa },
      {
        label: 'Urbanicity Type',
        value:
          data.urbanizationType?.charAt(0).toUpperCase() +
          data.urbanizationType?.slice(1).toLowerCase(),
      },
      { label: 'Radius (mi)', value: data.radius },
      { label: 'TA ID', value: data.taidId },
    ];

    return (
      <Table size="small">
        <TableBody>
          {locationFields.map(({ label, value }, index) => (
            <TableRow key={index}>
              <TableCell
                component="th"
                sx={{
                  pl: 0,
                  width: '150px',
                  fontWeight: 'bold',
                }}
              >
                {label}
              </TableCell>
              <TableCell sx={{ pl: 2 }}>{formatValue(value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const getActionColor = (action: string) => {
    return action.toLowerCase() === 'increase' ? 'success.main' : 'error.main';
  };

  const renderPulsePricing = () => {
    // Calculate averages and counts
    const increases = data.offerings.filter(
      (o) => o.priceAction.toLowerCase() === 'increase'
    );
    const decreases = data.offerings.filter(
      (o) => o.priceAction.toLowerCase() === 'decrease'
    );

    const avgIncreasePercentage =
      increases.length > 0
        ? increases.reduce((acc, curr) => acc + curr.priceChangePercentage, 0) /
          increases.length
        : 0;

    const avgDecreasePercentage =
      decreases.length > 0
        ? decreases.reduce((acc, curr) => acc + curr.priceChangePercentage, 0) /
          decreases.length
        : 0;

    const avgNationalIndex = data.avgNationalIndex;
    const avgMarketIndex = data.avgMarketIndex;

    // Group offerings by category
    const groupedOfferings = data.offerings.reduce<
      Record<string, typeof data.offerings>
    >((acc, offering) => {
      if (!acc[offering.category]) {
        acc[offering.category] = [];
      }
      acc[offering.category].push(offering);
      return acc;
    }, {});

    return (
      <>
        <Table size="small" sx={{ mb: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                sx={{ pl: 0, fontWeight: 'bold', width: '140px' }}
              >
                Dates Data Collected
              </TableCell>
              <TableCell sx={{ pl: 1 }}>
                {formatDateRange(data.datesCollected)}
              </TableCell>
            </TableRow>
            {data.competitors && (
              <TableRow>
                <TableCell
                  component="th"
                  sx={{ pl: 0, fontWeight: 'bold', width: '140px' }}
                >
                  Competitors
                </TableCell>
                <TableCell sx={{ pl: 1 }}>{data.competitors}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Box sx={{ display: 'flex', gap: 4, mb: 2 }}>
          {/* Pulse Index Section */}
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 'bold',
                  position: 'relative',
                  '&::before, &::after': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    width: '50px',
                    height: '1px',
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  },
                  '&::before': {
                    right: '100%',
                    marginRight: '8px',
                  },
                  '&::after': {
                    left: '100%',
                    marginLeft: '8px',
                  },
                }}
              >
                Pulse Index
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 4, justifyContent: 'center' }}>
              <Box>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Avg National Index
                </Typography>
                <Typography>{formatValue(avgNationalIndex)}</Typography>
              </Box>
              <Box>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Avg Market Index
                </Typography>
                <Typography>{formatValue(avgMarketIndex)}</Typography>
              </Box>
            </Box>
          </Box>

          {/* Pulse Tier Section */}
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 'bold',
                  position: 'relative',
                  '&::before, &::after': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    width: '50px',
                    height: '1px',
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  },
                  '&::before': {
                    right: '100%',
                    marginRight: '8px',
                  },
                  '&::after': {
                    left: '100%',
                    marginLeft: '8px',
                  },
                }}
              >
                Pulse Tier
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Chip
                label={
                  data.tierAction.charAt(0).toUpperCase() +
                  data.tierAction.slice(1)
                }
                color={
                  data.tierAction.toLowerCase() === 'increase'
                    ? 'success'
                    : 'error'
                }
                size="small"
                sx={{ mb: 1 }}
              />

              <Box sx={{ display: 'flex', gap: 4 }}>
                <Box>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Current Tier
                  </Typography>
                  <Typography>
                    {formatValue(data.pulseTier - data.tierChange)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Recommended Pulse Tier
                  </Typography>
                  <Typography>
                    {formatValue(data.pulseTier)}
                    {data.tierChange !== 0 && (
                      <Typography
                        component="span"
                        sx={{
                          color: getActionColor(data.tierAction),
                          ml: 1,
                        }}
                      >
                        (
                        {data.tierAction.toLowerCase() === 'increase'
                          ? '+'
                          : '-'}
                        {Math.abs(data.tierChange)})
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 1,
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 'bold',
              position: 'relative',
              mb: 2,
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                width: '50px',
                height: '1px',
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
              },
              '&::before': {
                right: '100%',
                marginRight: '8px',
              },
              '&::after': {
                left: '100%',
                marginLeft: '8px',
              },
            }}
          >
            Pulse Pricing
          </Typography>
          <Typography>
            {increases.length > 0 && (
              <span style={{ color: theme.palette.success.main }}>
                Increase {increases.length} prices by{' '}
                {formatPercentage(avgIncreasePercentage)}
              </span>
            )}
            {increases.length > 0 && decreases.length > 0 && ' and '}
            {decreases.length > 0 && (
              <span style={{ color: theme.palette.error.main }}>
                decrease {decreases.length} prices by{' '}
                {formatPercentage(avgDecreasePercentage)}
              </span>
            )}
          </Typography>
        </Box>

        {Object.entries(groupedOfferings)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([category, offerings]) => (
            <Accordion
              key={category}
              expanded={expandedAccordion === category}
              onChange={handleAccordionChange(category)}
              sx={{
                '&:before': { display: 'none' },
                boxShadow: 'none',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: '1rem' }} />}
                sx={{
                  px: 0.75,
                  minHeight: '36px !important',
                  '& .MuiAccordionSummary-content': {
                    margin: '8px 0',
                  },
                }}
              >
                <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>
                  {category} ({offerings.length} items)
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Table
                  size="small"
                  sx={{
                    '& td, & th': { px: 0.75, py: 0.5, fontSize: '0.75rem' },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', width: '15%' }}>
                        Subcategory
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', width: '12%' }}>
                        Current Price
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', width: '12%' }}>
                        Pulse Price
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', width: '15%' }}>
                        Pulse Tier Range
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', width: '15%' }}>
                        Pulse Price Action
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>
                        Price Change
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {offerings
                      .sort((a, b) =>
                        a.subCategory.localeCompare(b.subCategory)
                      )
                      .map((offering, index) => {
                        const actionColor = getActionColor(
                          offering.priceAction
                        );
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {formatValue(offering.subCategory)}
                            </TableCell>
                            <TableCell>
                              {formatCurrency(offering.actualPrice)}
                            </TableCell>
                            <TableCell>
                              {formatCurrency(offering.price)}
                            </TableCell>
                            <TableCell>
                              {formatTierRange(offering.tierRange)}
                            </TableCell>
                            <TableCell>
                              <Chip
                                label={
                                  offering.priceAction.charAt(0).toUpperCase() +
                                  offering.priceAction.slice(1)
                                }
                                color={
                                  offering.priceAction.toLowerCase() ===
                                  'increase'
                                    ? 'success'
                                    : 'error'
                                }
                                size="small"
                              />
                            </TableCell>
                            <TableCell sx={{ color: actionColor }}>
                              {formatCurrency(offering.priceChange)} (
                              {formatPercentage(offering.priceChangePercentage)}
                              )
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
      </>
    );
  };

  const mapHeight = map?.getContainer()?.clientHeight;
  const halfMapHeight = mapHeight ? mapHeight / 2 : 0;
  return (
    <Popup
      latitude={data.popupCoordinates.latitude}
      longitude={data.popupCoordinates.longitude}
      closeButton={false}
      closeOnClick={false}
      maxWidth="500px"
    >
      <Card
        sx={{
          maxWidth: 500,
          boxShadow: 'none',
          maxHeight: halfMapHeight,
          overflow: 'auto',
        }}
      >
        <CardContent sx={{ padding: 0.75, position: 'relative' }}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              padding: '4px',
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              mb: 1.5,
              pr: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Box sx={{ width: '50%', mr: 2 }}>
                  <Typography variant="h6" sx={{ mb: 0.75, fontSize: '1rem' }}>
                    {data.facilityName}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ mb: 0.5, display: 'block', fontSize: '0.75rem' }}
                  >
                    {data.address}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ display: 'block', mb: 0.75, fontSize: '0.75rem' }}
                  >
                    {data.coordinates}
                  </Typography>
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                      minHeight: 32,
                      '& .MuiTab-root': {
                        minHeight: 32,
                        padding: '4px 8px',
                        fontSize: '0.75rem',
                      },
                    }}
                  >
                    <Tab label="Pulse Price" />
                    <Tab label="Location Info" />
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Box>

          <TabPanel value={tabValue} index={0}>
            {renderPulsePricing()}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {renderLocationInfo()}
          </TabPanel>
        </CardContent>
      </Card>
    </Popup>
  );
};

export default FranchisePricingPopup;
