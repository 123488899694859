import {
  LayerConfig,
  LayerType,
} from '~/src/features/dynamic-map-controller/components/MapLayersMenu/';
import { mccombsLayers } from '~/src/features/dynamic-map-controller/data/mccombsLayers';
import { KidstrongCustomerHeatmapComponent } from '../components/layerControllers/KidstrongCustomerHeatmapComponent';
import { Color } from './colors';
import housingAffordabilityLayers from './housingAffordabilityLayers';
import { squareMetersPerSquareMile } from './measurementSystems';
import powerPlantsLayers from './powerPlantsLayers';

export const customerLayers: Record<string, LayerConfig> = {
  topsail_V6_seed_point_all: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'All - Topsail Final Model — Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'ts_v6_seed_pt',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_pt_v6_layer',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      colors: [Color.red, Color.darkGreen],
      threshold: [0, 1],
      opacityStops: [1, 1],
      expression: [
        'case',
        [
          'any',
          ['==', ['get', 'qualified'], 'True'],
          ['==', ['get', 'qualified'], 'true'],
          ['==', ['get', 'qualified'], true],
        ],
        1,
        0,
      ],
      hideBoundValues: true,
      boundLabels: ['Not Qualified', 'Qualified'],
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Performance Index': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          'Generator Count': {
            key: 'poi_generator_count',
            expression: ['get', 'poi_generator_count'],
            format: 'integer',
          },
        },
        { Urbanization: 'urbanization' },
        { Urbanicity: 'urbanicity' },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Qualification Status': {
            key: 'qualified',
            expression: [
              'case',
              [
                'any',
                ['==', ['get', 'qualified'], 'True'],
                ['==', ['get', 'qualified'], 'true'],
                ['==', ['get', 'qualified'], true],
              ],
              'Qualified',
              [
                'any',
                ['==', ['get', 'qualified'], 'False'],
                ['==', ['get', 'qualified'], 'false'],
                ['==', ['get', 'qualified'], false],
              ],
              'Not Qualified',
              'Unknown',
            ],
          },
        },
        {
          'Disqualification Reason': {
            key: 'reason',
            expression: [
              'case',
              ['has', 'reason'],
              [
                'concat',
                ['upcase', ['slice', ['get', 'reason'], 0, 1]],
                ['downcase', ['slice', ['get', 'reason'], 1]],
              ],
              '-',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },

  topsail_V6_seed_point_primary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Primary - Topsail Final Model — Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'ts_v6_seed_pt',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_pt_v6_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'primary']],
    styleConfig: {
      colors: [Color.red, Color.darkGreen],
      threshold: [0, 1],
      opacityStops: [1, 1],
      expression: [
        'case',
        [
          'any',
          ['==', ['get', 'qualified'], 'True'],
          ['==', ['get', 'qualified'], 'true'],
          ['==', ['get', 'qualified'], true],
        ],
        1,
        0,
      ],
      hideBoundValues: true,
      boundLabels: ['Not Qualified', 'Qualified'],
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Performance Index': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          'Generator Count': {
            key: 'poi_generator_count',
            expression: ['get', 'poi_generator_count'],
            format: 'integer',
          },
        },
        { Urbanization: 'urbanization' },
        { Urbanicity: 'urbanicity' },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Qualification Status': {
            key: 'qualified',
            expression: [
              'case',
              [
                'any',
                ['==', ['get', 'qualified'], 'True'],
                ['==', ['get', 'qualified'], 'true'],
                ['==', ['get', 'qualified'], true],
              ],
              'Qualified',
              [
                'any',
                ['==', ['get', 'qualified'], 'False'],
                ['==', ['get', 'qualified'], 'false'],
                ['==', ['get', 'qualified'], false],
              ],
              'Not Qualified',
              'Unknown',
            ],
          },
        },
        {
          'Disqualification Reason': {
            key: 'reason',
            expression: [
              'case',
              ['has', 'reason'],
              [
                'concat',
                ['upcase', ['slice', ['get', 'reason'], 0, 1]],
                ['downcase', ['slice', ['get', 'reason'], 1]],
              ],
              '-',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },

  topsail_V6_seed_point_secondary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Secondary - Topsail Final Model — Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'ts_v6_seed_pt',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_pt_v6_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'secondary']],
    styleConfig: {
      colors: [Color.red, Color.darkGreen],
      threshold: [0, 1],
      opacityStops: [1, 1],
      expression: [
        'case',
        [
          'any',
          ['==', ['get', 'qualified'], 'True'],
          ['==', ['get', 'qualified'], 'true'],
          ['==', ['get', 'qualified'], true],
        ],
        1,
        0,
      ],
      hideBoundValues: true,
      boundLabels: ['Not Qualified', 'Qualified'],
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Performance Index': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          'Generator Count': {
            key: 'poi_generator_count',
            expression: ['get', 'poi_generator_count'],
            format: 'integer',
          },
        },
        { Urbanization: 'urbanization' },
        { Urbanicity: 'urbanicity' },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Qualification Status': {
            key: 'qualified',
            expression: [
              'case',
              [
                'any',
                ['==', ['get', 'qualified'], 'True'],
                ['==', ['get', 'qualified'], 'true'],
                ['==', ['get', 'qualified'], true],
              ],
              'Qualified',
              [
                'any',
                ['==', ['get', 'qualified'], 'False'],
                ['==', ['get', 'qualified'], 'false'],
                ['==', ['get', 'qualified'], false],
              ],
              'Not Qualified',
              'Unknown',
            ],
          },
        },
        {
          'Disqualification Reason': {
            key: 'reason',
            expression: [
              'case',
              ['has', 'reason'],
              [
                'concat',
                ['upcase', ['slice', ['get', 'reason'], 0, 1]],
                ['downcase', ['slice', ['get', 'reason'], 1]],
              ],
              '-',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },
  topsail_V6_opt_points_all: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'All - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_v6_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_pt_v6_layer',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.darkGreen, Color.yellow],
      threshold: [1, 2],
      opacityStops: [1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
      boundLabels: ['Primary', 'Secondary'],
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Performance Index': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        { TAID: 'taid' },
        {
          Urbanicity: 'urbanicity',
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: ['coalesce', ['get', 'poi_generator_count'], 0],
          },
        },
      ],
    },
  },
  topsail_V6_opt_points_primary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Primary - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_v6_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_pt_v6_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'primary']],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.darkGreen, Color.yellow],
      threshold: [1, 2],
      opacityStops: [1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
      boundLabels: ['Primary', 'Secondary'],
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Performance Index': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        { TAID: 'taid' },
        {
          Urbanicity: 'urbanicity',
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: ['coalesce', ['get', 'poi_generator_count'], 0],
          },
        },
      ],
    },
  },
  topsail_V6_opt_points_secondary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Secondary - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_v6_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_pt_v6_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'secondary']],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.darkGreen, Color.yellow],
      threshold: [1, 2],
      opacityStops: [1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
      boundLabels: ['Primary', 'Secondary'],
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Performance Index': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        { TAID: 'taid' },
        {
          Urbanicity: 'urbanicity',
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: ['coalesce', ['get', 'poi_generator_count'], 0],
          },
        },
      ],
    },
  },
  topsail_V6_opt_boundaries_all: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'All - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_v6_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_poly_v6_layer',
    },
    filter: ['all', ['has', 'taid'], ['has', 'ota_type']],
    styleConfig: {
      colors: [Color.darkGreen, Color.yellow],
      threshold: [1, 2],
      opacityStops: [1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
      boundLabels: ['Primary', 'Secondary'],
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Performance Index': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        { TAID: 'taid' },
        {
          Urbanicity: 'urbanicity',
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: ['coalesce', ['get', 'poi_generator_count'], 0],
          },
        },
      ],
    },
  },
  topsail_V6_opt_boundaries_primary: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Primary - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_v6_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_poly_v6_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'primary']],
    styleConfig: {
      colors: [Color.darkGreen, Color.yellow],
      threshold: [1, 2],
      opacityStops: [1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
      boundLabels: ['Primary', 'Secondary'],
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Performance Index': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        { TAID: 'taid' },
        {
          Urbanicity: 'urbanicity',
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: ['coalesce', ['get', 'poi_generator_count'], 0],
          },
        },
      ],
    },
  },
  topsail_V6_opt_boundaries_secondary: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Secondary - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_v6_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_poly_v6_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'secondary']],
    styleConfig: {
      colors: [Color.darkGreen, Color.yellow],
      threshold: [1, 2],
      opacityStops: [1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
      boundLabels: ['Primary', 'Secondary'],
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Performance Index': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          Drivetime: 'drivetime',
        },
        { TAID: 'taid' },
        {
          Urbanicity: 'urbanicity',
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: ['coalesce', ['get', 'poi_generator_count'], 0],
          },
        },
      ],
    },
  },

  topsail_final_seed_point_all: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'All - Topsail Final Model — Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_seed_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_pt_final_layer',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      hideBoundValues: false,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          'Generator Count': {
            key: 'poi_generator_count',
            expression: ['get', 'poi_generator_count'],
            format: 'integer',
          },
        },
        { 'Urbanization Type': 'urbanization' },
        { 'Urbanicity Type': 'urbanicity' },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Qualification Status': {
            key: 'qualified',
            expression: [
              'case',
              ['==', ['get', 'qualified'], 'True'],
              'Qualified',
              ['==', ['get', 'qualified'], 'False'],
              'Not Qualified',
              'Unknown',
            ],
          },
        },
        {
          'Disqualification Reason': {
            key: 'reason',
            expression: [
              'case',
              ['has', 'reason'],
              [
                'concat',
                ['upcase', ['slice', ['get', 'reason'], 0, 1]],
                ['downcase', ['slice', ['get', 'reason'], 1]],
              ],
              '-',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },

  topsail_final_seed_point_primary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Primary - Topsail Final Model — Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_seed_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_pt_final_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'primary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: ['case', ['==', ['get', 'ota_type'], 'primary'], 1, 0],
      hideBoundValues: false,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          'Generator Count': {
            key: 'poi_generator_count',
            expression: ['get', 'poi_generator_count'],
            format: 'integer',
          },
        },
        { 'Urbanization Type': 'urbanization' },
        { 'Urbanicity Type': 'urbanicity' },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Qualification Status': {
            key: 'qualified',
            expression: [
              'case',
              ['==', ['get', 'qualified'], 'True'],
              'Qualified',
              ['==', ['get', 'qualified'], 'False'],
              'Not Qualified',
              'Unknown',
            ],
          },
        },
        {
          'Disqualification Reason': {
            key: 'reason',
            expression: [
              'case',
              ['has', 'reason'],
              [
                'concat',
                ['upcase', ['slice', ['get', 'reason'], 0, 1]],
                ['downcase', ['slice', ['get', 'reason'], 1]],
              ],
              '-',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },

  topsail_final_seed_point_secondary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Secondary - Topsail Final Model — Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_seed_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_pt_final_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'secondary']],
    styleConfig: {
      colors: [Color.transparent, Color.yellow],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: ['case', ['==', ['get', 'ota_type'], 'secondary'], 1, 0],
      hideBoundValues: false,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          'Generator Count': {
            key: 'poi_generator_count',
            expression: ['get', 'poi_generator_count'],
            format: 'integer',
          },
        },
        { 'Urbanization Type': 'urbanization' },
        { 'Urbanicity Type': 'urbanicity' },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Qualification Status': {
            key: 'qualified',
            expression: [
              'case',
              ['==', ['get', 'qualified'], 'True'],
              'Qualified',
              ['==', ['get', 'qualified'], 'False'],
              'Not Qualified',
              'Unknown',
            ],
          },
        },
        {
          'Disqualification Reason': {
            key: 'reason',
            expression: [
              'case',
              ['has', 'reason'],
              [
                'concat',
                ['upcase', ['slice', ['get', 'reason'], 0, 1]],
                ['downcase', ['slice', ['get', 'reason'], 1]],
              ],
              '-',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },

  topsail_final_seed_point_tertiary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Tertiary - Topsail Final Model — Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_seed_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_pt_final_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'tertiary']],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: ['case', ['==', ['get', 'ota_type'], 'tertiary'], 1, 0],
      hideBoundValues: false,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          'Generator Count': {
            key: 'poi_generator_count',
            expression: ['get', 'poi_generator_count'],
            format: 'integer',
          },
        },
        { 'Urbanization Type': 'urbanization' },
        { 'Urbanicity Type': 'urbanicity' },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Qualification Status': {
            key: 'qualified',
            expression: [
              'case',
              ['==', ['get', 'qualified'], 'True'],
              'Qualified',
              ['==', ['get', 'qualified'], 'False'],
              'Not Qualified',
              'Unknown',
            ],
          },
        },
        {
          'Disqualification Reason': {
            key: 'reason',
            expression: [
              'case',
              ['has', 'reason'],
              [
                'concat',
                ['upcase', ['slice', ['get', 'reason'], 0, 1]],
                ['downcase', ['slice', ['get', 'reason'], 1]],
              ],
              '-',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },
  topsail_final_opt_points_all: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'All - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_pt_final_layer',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: ['coalesce', ['get', 'poi_generator_count'], 0],
          },
        },
      ],
    },
  },
  topsail_final_opt_points_primary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Primary - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_pt_final_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'primary']],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
              0,
            ],
          },
        },
      ],
    },
  },
  topsail_final_opt_points_secondary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Secondary - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_pt_final_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'secondary']],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
              0,
            ],
          },
        },
      ],
    },
  },
  topsail_final_opt_points_tertiary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Tertiary - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_pt_final_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'tertiary']],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
              0,
            ],
          },
        },
      ],
    },
  },
  topsail_final_opt_boundaries_all: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'All - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_poly_final_layer',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
              0,
            ],
          },
        },
      ],
    },
  },
  topsail_final_opt_boundaries_primary: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Primary - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_poly_final_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'primary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
              0,
            ],
          },
        },
      ],
    },
  },
  topsail_final_opt_boundaries_secondary: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Secondary - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_poly_final_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'secondary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
              0,
            ],
          },
        },
      ],
    },
  },
  topsail_final_opt_boundaries_tertiary: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Tertiary - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'opt_poly_final_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'tertiary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'POI Generator Count': {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
              0,
            ],
          },
        },
      ],
    },
  },
  kidstrong_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '05/20/2024 — 06/20/2024 - Kidstrong',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'kidstrong_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 0 - not included
      // 90th percentile: 259
      // 95th percentile: 545
      // 99th percentile: 1550
      threshold: [0, 259, 545, 1550, 6000],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'kidstrong__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'kidstrong__date_range',
      fields: [
        {
          Impressions: {
            key: 'kidstrong__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'kidstrong__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'kidstrong__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  kidstrong_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '09/17/2024 — 10/17/2024 - Kidstrong',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'kidstrong_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 0 - not included
      // 90th percentile: 259
      // 95th percentile: 545
      // 99th percentile: 1550
      threshold: [0, 259, 545, 1550, 6000],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'kidstrong_v2__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'kidstrong_v2__date_range',
      fields: [
        {
          Impressions: {
            key: 'kidstrong_v2__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'kidstrong_v2__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'kidstrong_v2__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  kids_gymnastics_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '05/25/2024 — 06/25/2024 - Kids Gymnastics',
    category: 'Gyms.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'kids_gymnastics_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 0 - not included
      // 90th percentile: 80
      // 95th percentile: 205
      // 99th percentile: 439
      threshold: [0, 80, 205, 439, 1400],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'kids_gymnastics__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'kids_gymnastics__date_range',
      fields: [
        {
          Impressions: {
            key: 'kids_gymnastics__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'kids_gymnastics__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'kids_gymnastics__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  kids_gymnastics_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '09/18/2024 — 10/18/2024 - Kids Gymnastics',
    category: 'Gyms.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'kids_gymnastics_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 0 - not included
      // 90th percentile: 80
      // 95th percentile: 205
      // 99th percentile: 439
      threshold: [0, 80, 205, 439, 1400],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'kids_gymnastics_v2__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'kids_gymnastics_v2__date_range',
      fields: [
        {
          Impressions: {
            key: 'kids_gymnastics_v2__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'kids_gymnastics_v2__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'kids_gymnastics_v2__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  topsail_search_demand_v3: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '09/30/2024 — 10/30/2024 - Search Demand — Topsail',
    category: 'Restaurant.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'topsail_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 10
      // 90th percentile: 236
      // 95th percentile: 490
      // 99th percentile: 1067
      threshold: [0, 10, 236, 490, 1067, 6000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'topsail_v3__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'date_range',
      fields: [
        {
          Impressions: {
            key: 'topsail_v3__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'topsail_v3__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'topsail_v3__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  topsail_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '06/07/2024 — 07/07/2024 - Search Demand — Topsail',
    category: 'Restaurant.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'topsail_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 10
      // 90th percentile: 236
      // 95th percentile: 490
      // 99th percentile: 1067
      threshold: [0, 10, 236, 490, 1067, 6000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'topsail_v2__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'date_range',
      fields: [
        {
          Impressions: {
            key: 'topsail_v2__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'topsail_v2__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'topsail_v2__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  topsail_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '04/21/2024 — 05/21/2024 - Search Demand — Topsail',
    category: 'Restaurant.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'topsail_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 10
      // 90th percentile: 236
      // 95th percentile: 490
      // 99th percentile: 1067
      threshold: [0, 10, 236, 490, 1067, 6000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'topsail__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'topsail__date_range',
      fields: [
        {
          Impressions: {
            key: 'topsail__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'topsail__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'topsail__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  kidstrong_customer_heatmap: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Zipcode - Customer Location Heatmap',
    category: 'Restaurant.Search Demand',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Client Data',
      source: 'luketruitt1.client_data_zipcode',
      source_layer: 'client_data_zipcode',
    },
    styleConfig: {
      threshold: [0, 1, 5, 10, 15],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
      ],
      expression: ['coalesce', ['get', 'kidstrong_cus_count'], 0],
      fallback: 0,
      unit: 'customers',
      boundLabels: ['Low', 'High'],
      hideBoundValues: true,
    },
    dataConfig: {
      header: 'zip_code',
      fields: [
        {
          'Customer Count': {
            expression: ['coalesce', ['get', 'kidstrong_cus_count'], 0],
          },
        },
      ],
    },
  },
  kidstrong_customer_heatmap_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Zipcode - Customer Location Heatmap by Facility',
    category: 'Restaurant.Search Demand',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Client Data',
      source: 'luketruitt1.client_data_zipcode_v2',
      source_layer: 'client_data_zipcode_v2',
    },
    styleConfig: {
      threshold: [0, 1, 5, 10, 15],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
      ],
      fallback: 0,
      unit: 'customers',
      boundLabels: ['Low', 'High'],
      hideBoundValues: true,
    },
    controllableComponent: KidstrongCustomerHeatmapComponent,
    dataConfig: {
      header: 'zip_code',
      fields: [],
    },
  },
  seafood_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Search Demand - Seafood',
    category: 'Restaurant.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 18
      // 90th percentile: 357
      // 95th percentile: 737
      // 99th percentile: 2118
      threshold: [0, 18, 357, 737, 2118, 12000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'seafood__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'seafood__date_range',
      fields: [
        {
          Impressions: {
            key: 'seafood__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'seafood__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'seafood__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  fitness_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '07/22/2024 — 08/22/2024 - Search Demand — Fitness',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'fitness_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 50, 530, 2115, 8400],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'fitness_demand__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'fitness_demand__date_range',
      fields: [
        {
          Impressions: {
            key: 'fitness_demand__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'fitness_demand__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'fitness_demand__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  city_lifestyle_zips: {
    type: LayerType.Insights,
    displayName: 'City Lifestyle - Zip Codes',
    featureType: 'polygon',
    category: 'Miscellaneous.City Lifestyle',
    groupName: 'custom_layers',
    styleConfig: {
      colors: ['rgba(173, 216, 230, 0.5)'],
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'primary_city',
      fields: [
        { Population: 'population' },
        {
          'Median Home Value': {
            key: 'avg_median_home_value',
            format: 'dollars',
          },
        },
        {
          'Median Household Income': {
            key: 'avg_median_household_income',
            format: 'dollars',
          },
        },
        { 'Owner Occupied Homes': 'owner_occupied_homes' },
        {
          'Percent Owner Occupied': {
            key: 'percent_owner_occupied',
            format: 'percent',
          },
        },
        { 'Seasonal Population': 'seasonal_population' },
        {
          'Percent Seasonal Population': {
            key: 'percent_seasonal_population',
            format: 'percent',
          },
        },
        { 'Count of Local Businesses': 'total_businesses' },
      ],
    },
    sourceTileset: {
      label: 'Zip Codes',
      source: 'luketruitt1.city_lifestyle_zips_2024',
      source_layer: 'city-life-zipcodes',
    },
  },
  city_lifestyle_places: {
    type: LayerType.Insights,
    displayName: 'City Lifestyle - Cities',
    featureType: 'polygon',
    category: 'Miscellaneous.City Lifestyle',
    groupName: 'custom_layers',
    styleConfig: {
      colors: ['rgba(173, 216, 230, 0.5)'],
    },
    dataConfig: {
      header: 'city_id',
      fields: [
        { Population: 'population' },
        {
          'Median Home Value': {
            key: 'avg_median_home_value',
            format: 'dollars',
          },
        },
        {
          'Median Household Income': {
            key: 'avg_median_household_income',
            format: 'dollars',
          },
        },
        { 'Owner Occupied Homes': 'owner_occupied_homes' },
        {
          'Percent Owner Occupied': {
            key: 'percent_owner_occupied',
            format: 'percent',
          },
        },
        { 'Seasonal Population': 'seasonal_population' },
        {
          'Percent Seasonal Population': {
            key: 'percent_seasonal_population',
            format: 'percent',
          },
        },
        {
          'Count of Local Businesses': 'total_businesses',
        },
        { 'Zip Code Count': 'zip_code_count' },
        {
          'Zip Codes': {
            key: 'zip_codes',
            format: 'chips',
          },
        },
      ],
    },
    sourceTileset: {
      label: 'Cities',
      source: 'luketruitt1.cities-2024',
      source_layer: 'cities-2024',
    },
  },
  city_lifestyle_local_businesses: {
    type: LayerType.Insights,
    displayName: 'City Lifestyle - Local Businesses',
    featureType: 'polygon',
    category: 'Miscellaneous.City Lifestyle',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'City Lifestyle - Local Businesses',
      source: 'luketruitt1.local_business_count_2024',
      source_layer: 'local-business-zip-counts',
    },
    styleConfig: {
      colors: [Color.white, Color.blue, Color.blue, Color.purple],
      threshold: [0, 50, 400, 800],
      opacityStops: [0, 0.5, 1, 1],
      fallback: 0,
      expression: [
        'case',
        ['==', ['get', 'area'], 0],
        0,
        [
          'round',
          [
            '*',
            ['/', ['get', 'total_businesses'], ['get', 'area']],
            squareMetersPerSquareMile,
          ],
        ],
      ],
      unit: 'businesses / mi\u00B2',
    },
    dataConfig: {
      header: 'zip_code',
      fields: [
        { 'Count of Local Businesses': 'total_businesses' },
        {
          'Local Businesses / mi\u00B2': {
            expression: [
              'case',
              ['==', ['get', 'area'], 0],
              0,
              [
                'round',
                [
                  '*',
                  ['/', ['get', 'total_businesses'], ['get', 'area']],
                  squareMetersPerSquareMile,
                ],
              ],
            ],
          },
        },
      ],
    },
  },
  multi_family_housing: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Block Group - Multi-Family Housing Units',
    category: 'Population.Income',
    groupName: 'demographic_layers',
    class: 'housing',
    sourceTileset: {
      label: 'Block Group Insights',
      source: 'luketruitt1.block_group_insights',
      source_layer: 'block_group_insights',
    },
    styleConfig: {
      threshold: [0, 100, 3000, 7500],
      colors: [Color.white, Color.yellow, Color.orange, Color.red],
      fallback: 0,
      expression: [
        'case',
        ['==', ['get', 'area'], 0],
        0,
        [
          'round',
          [
            '*',
            ['/', ['get', 'mfh_units_total'], ['get', 'area']],
            squareMetersPerSquareMile,
          ],
        ],
      ],
      opacityStops: [0, 1, 1, 1],
      unit: 'units / mi\u00B2',
    },
    dataConfig: {
      header: 'id',
      fields: [
        { 'Multi-Family Units': 'mfh_units_total' },
        {
          'Units / mi\u00B2': {
            expression: [
              'case',
              ['==', ['get', 'area'], 0],
              0,
              [
                'round',
                [
                  '*',
                  ['/', ['get', 'mfh_units_total'], ['get', 'area']],
                  squareMetersPerSquareMile,
                ],
              ],
            ],
          },
        },
      ],
    },
  },
  bg_opportunity_zones: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Block Group - Opportunity Zones',
    category: 'Population.Income',
    groupName: 'boundary_layers',
    sourceTileset: {
      label: 'Block Group Opportunity Zones',
      source: 'luketruitt1.bg_opportunity_zones',
      source_layer: 'bg_opportunity_zones',
    },
    isBoundary: true,
    boundaryThickness: 3, // Add custom thickness
    filter: ['==', ['get', 'is_opportunity_zone'], true],
    styleConfig: {
      colors: [Color.transparent],
      fallback: 0,
      opacityStops: [0, 1],
    },
    dataConfig: {
      header: 'id',
    },
  },
  ...housingAffordabilityLayers,
  zip_franchising_interest: {
    type: LayerType.Insights,
    featureType: 'polygon',
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 173, 706, 1766.48, 10000],
      opacityStops: [0, 0, 1, 1, 1],
      colors: [
        Color.yellow,
        Color.yellowOrange,
        Color.orange,
        Color.darkOrange,
        Color.red,
      ],
      expression: ['get', 'franchising_impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'id',
      fields: [{ 'Franchising Impressions': 'franchising_impressions' }],
    },
    sourceTileset: {
      label: 'Zip Codes',
      source: 'luketruitt1.insights_zipcode',
      source_layer: 'insights_zipcode',
    },
    displayName: 'Franchising Demand',
    permission: 'read:insight_franchising_interest',
    category: 'Retail.Franchise',
    groupName: 'search_layers',
  },
  zip_consumer_retail_intent: {
    type: LayerType.Insights,
    featureType: 'polygon',
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 11787.08, 34384.36, 98819.33, 134000],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.yellow,
        Color.yellowOrange,
        Color.orange,
        Color.darkOrange,
        Color.red,
      ],
      expression: ['get', 'retail_intent_impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'id',
      fields: [{ 'Retail Intent Impressions': 'retail_intent_impressions' }],
    },
    sourceTileset: {
      label: 'Zip Codes',
      source: 'luketruitt1.insights_zipcode',
      source_layer: 'insights_zipcode',
    },
    displayName: 'Consumer Demand',
    permission: 'read:insight_consumer_retail_intent',
    category: 'Retail',
    groupName: 'search_layers',
  },
  zip_search_carls_jr: {
    type: LayerType.Insights,
    featureType: 'polygon',
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 13, 27, 41, 42],
      opacityStops: [0, 1, 1, 1],
      colors: [
        Color.yellow,
        Color.yellowOrange,
        Color.orange,
        Color.darkOrange,
        Color.red,
      ],
      expression: ['get', 'carls_junior_impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'id',
      fields: [{ 'Carls Jr Impressions': 'carls_junior_impressions' }],
    },
    sourceTileset: {
      label: 'Zip Codes',
      source: 'luketruitt1.insights_zipcode',
      source_layer: 'insights_zipcode',
    },
    displayName: `Consumer Demand - Carls Jr`,
    permission: 'read:ent_walkons',
    category: 'Restaurant',
    groupName: 'search_layers',
  },
  designated_fuel_corridors: {
    type: LayerType.Insights,
    featureType: 'line',
    sourceTileset: {
      label: 'Aiquetech',
      source: 'luketruitt1.aiquetech',
      source_layer: 'designated_fuel_corridors',
    },
    displayName: 'Designated Fuel Corridors',
    category: 'Miscellaneous.Search American',
    groupName: 'custom_layers',
    styleConfig: { colors: ['#000'] },
  },
  power_transmission_lines: {
    type: LayerType.Insights,
    featureType: 'line',
    sourceTileset: {
      label: 'Aiquetech',
      source: 'luketruitt1.aiquetech',
      source_layer: 'power_transmission_lines',
    },
    displayName: 'Power Transmission Lines',
    styleConfig: { colors: [Color.red] },
    category: 'Miscellaneous.Search American',
    groupName: 'custom_layers',
  },
  topsail_traditional_seed_point: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Traditional Model Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_point_layer',
    },
    filter: [
      'match',
      ['get', 'performance_classification'],
      'primary',
      true,
      'secondary',
      true,
      'tertiary',
      true,
      false,
    ],
    styleConfig: {
      colors: [Color.darkGreen, Color.yellow, Color.red],
      threshold: [1, 2, 3],
      opacityStops: [1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'performance_classification'], 'primary'],
        1,
        ['==', ['get', 'performance_classification'], 'secondary'],
        2,
        ['==', ['get', 'performance_classification'], 'tertiary'],
        3,
        0,
      ],
      boundLabels: ['Primary', 'Tertiary'],
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        {
          'Urbanization Type': {
            expression: [
              'case',
              ['<=', ['get', 'urbanicity_classification'], 3],
              'Suburban',
              ['>=', ['get', 'urbanicity_classification'], 4],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Urbanization Classification': {
            expression: [
              'match',
              ['get', 'urbanicity_classification'],
              0,
              'Unknown',
              1,
              'Rural',
              2,
              'In-Town',
              3,
              'Suburban',
              4,
              'Metropolitan',
              5,
              'Urban',
              6,
              'Urban Core',
              'Unknown',
            ],
          },
        },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  topsail_traditional_seed_point_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Traditional Model Seed Points V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_point_layer_v2',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: ['case', ['has', 'taid'], 1, 0],
      hideBoundValues: false,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'CBSA' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_non_trad_seed_point: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Non-Traditional Model Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'non_trad_seed_point_layer',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: ['case', ['has', 'taid'], 1, 0],
      hideBoundValues: false,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_sample_set: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Traditional Model Sample Set',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'sample_set_layer',
    },
    filter: [
      'case',
      ['<=', ['get', 'urbanization_classification'], 'suburban'],
      true,
      ['>=', ['get', 'urbanization_classification'], 'urban'],
      true,
      false,
    ],
    styleConfig: {
      colors: [Color.yellow, Color.red],
      threshold: [1, 2],
      opacityStops: [1, 1],
      expression: [
        'case',
        ['<=', ['get', 'urbanization_classification'], 'suburban'],
        1,
        ['>=', ['get', 'urbanization_classification'], 'urban'],
        2,
        0,
      ],
      fallback: 0,
      boundLabels: ['Suburban', 'Urban'],
      hideBoundValues: true,
    },
    dataConfig: {
      header: 'location_id',
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Location Name': 'location_name' },
        { 'Urbanization Classification': 'urbanization_classification' },
      ],
    },
  },
  topsail_traditional_primary_urban_points: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Primary Urban Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'primary'],
      ['>=', ['get', 'urbanicity_classification'], 4],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'primary'],
          ['>=', ['get', 'urbanicity_classification'], 4],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Classification': 'urbanicity_classification' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  topsail_traditional_primary_suburban_points: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Primary Suburban Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'primary'],
      ['<=', ['get', 'urbanicity_classification'], 3],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'primary'],
          ['<=', ['get', 'urbanicity_classification'], 3],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Classification': 'urbanicity_classification' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  topsail_traditional_primary_urban_boundaries: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Primary Urban Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'primary'],
      ['>=', ['get', 'urbanicity_classification'], 4],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'primary'],
          ['>=', ['get', 'urbanicity_classification'], 4],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Classification': 'urbanicity_classification' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  topsail_traditional_primary_suburban_boundaries: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Primary Suburban Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'primary'],
      ['<=', ['get', 'urbanicity_classification'], 3],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'primary'],
          ['<=', ['get', 'urbanicity_classification'], 3],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Classification': 'urbanicity_classification' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  plotr_retail_proximity_heatmap: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Plotr Retail Proximity Heatmap',
    category: 'Retail.RetailProximity',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Retail Proximity',
      source: 'luketruitt1.generators',
      source_layer: 'generators',
    },
    styleConfig: {
      colors: [
        Color.transparent,
        '#FFFF00', // Yellow
        '#FFA500', // Orange
        '#FF0000',
      ],
      threshold: [0, 1, 5, 9],
      opacityStops: [0, 1, 1, 1],
      unit: 'retail_proximity',
      boundLabels: ['Low', 'High'],
      expression: ['get', 'retail_proximity'],
    },
    dataConfig: {
      header: 'id',
      fields: [
        {
          'Retail Proximity Generators': {
            key: 'retail_proximity',
            format: 'integer',
          },
        },
      ],
    },
  },
  child_development_heatmap: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Plotr Child Development Heatmap',
    category: 'Education.ChildDevelopment',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Child Development',
      source: 'luketruitt1.generators',
      source_layer: 'generators',
    },
    styleConfig: {
      colors: [
        Color.transparent,
        '#FFFF00', // Yellow
        '#FFA500', // Orange
        '#FF0000',
      ],
      threshold: [0, 1, 3, 6],
      opacityStops: [0, 1, 1, 1],
      expression: ['get', 'child_development'],
      unit: 'child_development',
      boundLabels: ['Low', 'High'],
    },
    dataConfig: {
      header: 'id',
      fields: [
        {
          'Child Development Generators': {
            key: 'child_development',
            format: 'integer',
          },
        },
      ],
    },
  },
  topsail_traditional_primary_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Traditional Model - Primary Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v3',
    },
    filter: [
      'all',
      ['==', ['get', 'ota_type'], 'primary'],
      ['==', ['get', 'seasonality'], 'traditional'],
    ],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.darkGreen],
      threshold: [1],
      opacityStops: [1],
      expression: [
        'case',
        [
          'in',
          ['get', 'urbanicity'],
          [
            'literal',
            [
              'Rural (1)',
              'In-Town (2)',
              'Suburban (3)',
              'Metropolitan (4)',
              'Urban (5)',
              'Urban Core (6)',
            ],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Latitude, Longitude': {
            key: 'Latitude, Longitude',
            format: 'integer',
            expression: [
              'concat',
              ['number', ['get', 'latitude'], 6],
              ', ',
              ['number', ['get', 'longitude'], 6],
            ],
          },
        },
        { TAID: 'taid' },
        { Urbanicity: 'urbanicity' },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            format: 'percent',
          },
        },
        { 'Population Density - 3 Mile': 'pop_density' },
        { 'Workforce Density - 3 Mile': 'workforce_density' },
        { 'Drive Time': 'drivetime' },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non-traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        { 'Model Score': 'model_score' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Market Ranking': 'market_ranking' },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            format: 'percent',
          },
        },
        { 'POI Generator Count': 'poi_generator_count' },
      ],
    },
  },
  topsail_traditional_primary_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Primary Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v3',
    },
    filter: [
      'all',
      ['==', ['get', 'ota_type'], 'primary'],
      ['==', ['get', 'seasonality'], 'traditional'],
    ],
    styleConfig: {
      colors: [Color.darkGreen],
      threshold: [1],
      opacityStops: [1],
      expression: [
        'case',
        [
          'in',
          ['get', 'urbanicity'],
          [
            'literal',
            [
              'Rural (1)',
              'In-Town (2)',
              'Suburban (3)',
              'Metropolitan (4)',
              'Urban (5)',
              'Urban Core (6)',
            ],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Latitude, Longitude': {
            format: 'integer',
            expression: [
              'concat',
              ['number', ['get', 'latitude'], 6],
              ', ',
              ['number', ['get', 'longitude'], 6],
            ],
          },
        },
        { TAID: 'taid' },
        { Urbanicity: 'urbanicity' },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            format: 'percent',
          },
        },
        { 'Population Density - 3 Mile': 'pop_density' },
        { 'Workforce Density - 3 Mile': 'workforce_density' },
        { 'Drive Time': 'drivetime' },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non-traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        { 'Model Score': 'model_score' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Market Ranking': 'market_ranking' },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            format: 'percent',
          },
        },
        { 'POI Generator Count': 'poi_generator_count' },
      ],
    },
  },
  topsail_traditional_secondary_urban_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Secondary Urban Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'secondary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.yellow],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'secondary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_secondary_suburban_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Secondary Suburban Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v3',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'secondary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.yellow],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'secondary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_secondary_urban_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Secondary Urban Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v2',
    },
    styleConfig: {
      colors: [Color.transparent, Color.yellow],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'secondary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_secondary_suburban_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      'Topsail Traditional Model - Secondary Suburban Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'secondary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.yellow],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'secondary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_tertiary_urban_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Tertiary Urban Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'tertiary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'tertiary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_tertiary_suburban_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Tertiary Suburban Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'tertiary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'tertiary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_tertiary_urban_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Tertiary Urban Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'tertiary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'tertiary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_tertiary_suburban_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Tertiary Suburban Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'tertiary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'tertiary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_non_trad_points: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Non-Traditional Model - Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_non_trad_layer',
    },
    filter: ['all', ['==', ['get', 'seasonality'], 'non_traditional']],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.transparent, Color.darkGreen, Color.yellow],
      threshold: [0, 1, 2],
      opacityStops: [0, 1, 1],
      expression: [
        'case',

        ['==', ['get', 'performance_classification'], 'primary'],
        1,
        ['==', ['get', 'performance_classification'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        // { 'Drive Time': 'drivetime' },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              [
                'upcase',
                ['slice', ['get', 'performance_classification'], 0, 1],
              ],
              ['downcase', ['slice', ['get', 'performance_classification'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },
  topsail_non_trad_boundaries: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Non-Traditional Model - Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_non_trad_layer',
    },
    filter: ['all', ['==', ['get', 'seasonality'], 'non_traditional']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow],
      threshold: [0, 1, 2],
      opacityStops: [0, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'performance_classification'], 'primary'],
        1,
        ['==', ['get', 'performance_classification'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        // { 'Drive Time': 'drivetime' },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              [
                'upcase',
                ['slice', ['get', 'performance_classification'], 0, 1],
              ],
              ['downcase', ['slice', ['get', 'performance_classification'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },
  laundromat_walkin_self_service_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Search Demand - Laundromat Walk-in Self Service',
    category: 'Laundry.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 100, 500, 1000, 5000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'supermatt_walkin_self_service__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'supermatt_walkin_self_service__date_range',
      fields: [
        {
          Impressions: {
            key: 'supermatt_walkin_self_service__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'supermatt_walkin_self_service__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'supermatt_walkin_self_service__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  laundromat_pickup_delivery_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Search Demand - Laundromat Pickup & Delivery',
    category: 'Laundry.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 100, 500, 1000, 5000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'supermatt_pickup_delivery__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'supermatt_pickup_delivery__date_range',
      fields: [
        {
          Impressions: {
            key: 'supermatt_pickup_delivery__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'supermatt_pickup_delivery__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'supermatt_pickup_delivery__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  efc_customer_heatmap: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Customer Heatmap - EFC',
    category: 'EFC.Topsail',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Customer Heatmap',
      source: 'luketruitt1.customer_heatmap_efc',
      source_layer: 'customer_heatmap_efc',
    },
    styleConfig: {
      threshold: [0, 1, 5, 10, 15, 20, 30],
      opacityStops: [0, 1, 1, 1, 1, 1, 1],
      colors: [
        Color.transparent,
        Color.yellow,
        Color.yellowOrange,
        Color.orange,
        Color.darkOrange,
        Color.red,
        Color.purple,
      ],
      expression: ['get', 'count'],
      fallback: 0,
    },
    dataConfig: {
      header: 'id',
      fields: [{ Customers: 'count' }],
    },
  },
  pvolve_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '07/26/2024 — 08/26/2024 - Search Demand — Pvolve',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'pvolve_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 100, 500, 1000, 5000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'pvolve__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'pvolve__date_range',
      fields: [
        {
          Impressions: {
            key: 'pvolve__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'pvolve__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'pvolve__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  pvolve_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '10/18/2024 — 11/18/2024 - Search Demand — Pvolve',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'pvolve_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 84, 722, 6136],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'pvolve_v2__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'pvolve_v2__date_range',
      fields: [
        {
          Impressions: {
            key: 'pvolve_v2__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'pvolve_v2__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'pvolve_v2__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  fitness_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '10/20/2024 — 11/20/2024 - Search Demand — Fitness',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'fitness_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 191, 3652, 69808],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'fitness_demand_v2__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'fitness_demand_v2__date_range',
      fields: [
        {
          Impressions: {
            key: 'fitness_demand_v2__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'fitness_demand_v2__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'fitness_demand_v2__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  northside_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Search Demand - Northside Hospital',
    category: 'Healthcare.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 100, 500, 1000, 5000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'northside__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'northside__date_range',
      fields: [
        {
          Impressions: {
            key: 'northside__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'northside__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'northside__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  ...mccombsLayers,
  us_bodies_of_water: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'US Bodies of Water',
    category: 'Miscellaneous.Geography',
    groupName: 'boundary_layers',
    isBoundary: true,
    sourceTileset: {
      label: 'US Bodies of Water',
      source: 'luketruitt1.us_water_bodies',
      source_layer: 'us_water_bodies',
    },
    styleConfig: {
      colors: [Color.transparent],
    },
    dataConfig: {
      header: ['get', 'name'],
      fields: [
        { Type: 'type' },
        { 'Type Description': 'type_description' },
        {
          'Area (sq mi)': 'area_sqmi',
        },
      ],
    },
  },
  loves_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '11/11/2024 — 12/11/2024 - Search Demand — Loves',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'loves_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 9.5, 38, 112, 485],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'loves__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'loves__date_range',
      fields: [
        {
          Impressions: {
            key: 'loves__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'loves__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'loves__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  topgolf_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '11/11/2024 — 12/11/2024 - Search Demand — Topgolf',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'topgolf_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 7.6, 16, 64, 607, 5485],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'topgolf__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'topgolf__date_range',
      fields: [
        {
          Impressions: {
            key: 'topgolf__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'topgolf__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'topgolf__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  loves_seed_point: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Loves: Sample Set',
    category: 'Fitness.Locations',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Loves',
      source: 'luketruitt1.arco',
      source_layer: 'loves_sample',
    },
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.transparent, Color.blue, Color.orange, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'Performance Classification'], 'top performer'],
        1,
        ['==', ['get', 'Performance Classification'], 'bottom performer'],
        3,
        2,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Brand: ', ['get', 'Brand']],
      fields: [
        { Brand: 'Brand' },
        {
          'Performance Classification': {
            key: 'Performance Classification',
            expression: [
              'case',
              ['==', ['get', 'Performance Classification'], 'top performer'],
              'Top Performer',
              ['==', ['get', 'Performance Classification'], 'bottom performer'],
              'Bottom Performer',
              [
                '==',
                ['get', 'Performance Classification'],
                'standard location',
              ],
              'Standard Location',
              ['get', 'Performance Classification'],
            ],
          },
        },
        { CBSA: 'CBSA' },
        {
          'Urbanicity Type': {
            key: 'Urbanicity Type',
            expression: [
              'case',
              ['==', ['get', 'Urbanicity Type'], 'urban core (6)'],
              'Urban Core (6)',
              ['==', ['get', 'Urbanicity Type'], 'urban (5)'],
              'Urban (5)',
              ['==', ['get', 'Urbanicity Type'], 'metropolitan (4)'],
              'Metropolitan (4)',
              ['==', ['get', 'Urbanicity Type'], 'suburban (3)'],
              'Suburban (3)',
              ['==', ['get', 'Urbanicity Type'], 'in-town (2)'],
              'In-Town (2)',
              ['==', ['get', 'Urbanicity Type'], 'rural (1)'],
              'Rural (1)',
              ['get', 'Urbanicity Type'],
            ],
          },
        },
        {
          'Total Foot Traffic': {
            key: 'Total_Foot_Traffic',
            expression: ['to-number', ['get', 'Total Foot Traffic']],
            format: 'integer',
          },
        },
        { 'Ranking Year': 'Ranking Year' },
        {
          'Yearly Mean Foot Traffic': {
            key: 'Yearly_Mean_Foot_Traffic',
            expression: ['to-number', ['get', 'Yearly Mean Foot Traffic']],
            format: 'integer',
          },
        },
        {
          'NTL Ranking': {
            key: 'NTL_Ranking',
            expression: ['to-number', ['get', 'NTL Ranking']],
            format: 'integer',
          },
        },
        {
          'NTL Index': {
            key: 'NTL_Index',
            expression: ['to-number', ['get', 'NTL Index']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'Market_Ranking',
            expression: ['to-number', ['get', 'Market Ranking']],
            format: 'integer',
          },
        },
        {
          'Market Index': {
            key: 'Market_Index',
            expression: ['to-number', ['get', 'Market Index']],
            format: 'integer',
          },
        },
        { 'POI Generator': 'POI Generator' },
        {
          'Income $25k-50k': {
            key: 'Income_25k_50k',
            expression: ['to-number', ['get', 'Income 25k-50k Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Income $50k-75k': {
            key: 'Income_50k_75k',
            expression: ['to-number', ['get', 'Income 50k-75k Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Income $75k-100k': {
            key: 'Income_75k_100k',
            expression: [
              'to-number',
              ['get', 'Income 75k-100k Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Income $25k and Less': {
            key: 'Income_25k_and_Less',
            expression: [
              'to-number',
              ['get', 'Income 25k and Less Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Income $125k and Above': {
            key: 'Income_125k_and_Above',
            expression: [
              'to-number',
              ['get', 'Income 125k and Above Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Gender - Male': {
            key: 'Gender_Male',
            expression: ['to-number', ['get', 'Gender Male Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Gender - Female': {
            key: 'Gender_Female',
            expression: ['to-number', ['get', 'Gender Female Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Age 18-29': {
            key: 'Age_18_29',
            expression: ['to-number', ['get', 'Age 18-29 Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Age 30-39': {
            key: 'Age_30_39',
            expression: ['to-number', ['get', 'Age 30-39 Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Age 40-49': {
            key: 'Age_40_49',
            expression: ['to-number', ['get', 'Age 40-49 Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Age 50-59': {
            key: 'Age_50_59',
            expression: ['to-number', ['get', 'Age 50-59 Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Age 60-69': {
            key: 'Age_60_69',
            expression: ['to-number', ['get', 'Age 60-69 Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Age 70-79': {
            key: 'Age_70_79',
            expression: ['to-number', ['get', 'Age 70-79 Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Age 80+': {
            key: 'Age_80_and_Above',
            expression: [
              'to-number',
              ['get', 'Age 80 and Above Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Race - White': {
            key: 'Race_White',
            expression: ['to-number', ['get', 'Race White Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Race - Black': {
            key: 'Race_Black',
            expression: ['to-number', ['get', 'Race Black Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Race - Asian': {
            key: 'Race_Asian',
            expression: ['to-number', ['get', 'Race Asian Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Race - Amerindian': {
            key: 'Race_Amerindian',
            expression: [
              'to-number',
              ['get', 'Race Amerindian Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Race - Two or More': {
            key: 'Race_Two_or_More',
            expression: [
              'to-number',
              ['get', 'Race Two or More Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Race - Other': {
            key: 'Race_Other',
            expression: ['to-number', ['get', 'Race Other Total Visitors']],
            format: 'integer',
          },
        },
        {
          'Education - High School': {
            key: 'Education_High_School',
            expression: [
              'to-number',
              ['get', 'Education High School Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Education - College Without Degree': {
            key: 'Education_College_Without_Degree',
            expression: [
              'to-number',
              ['get', 'Education College Without Degree Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Education - Associate': {
            key: 'Education_Associate',
            expression: [
              'to-number',
              ['get', 'Education Associate Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Education - Bachelor': {
            key: 'Education_Bachelor',
            expression: [
              'to-number',
              ['get', 'Education Bachelor Total Visitors'],
            ],
            format: 'integer',
          },
        },
        {
          'Education - Master and Above': {
            key: 'Education_Master_and_Above',
            expression: [
              'to-number',
              ['get', 'Education Master and Above Total Visitors'],
            ],
            format: 'integer',
          },
        },
        { 'Opened On': 'Opened On' },
        { 'Closed On': 'Closed On' },
        {
          Seasonality: {
            key: 'Seasonality',
            expression: [
              'case',
              ['==', ['get', 'Seasonality'], 'traditional'],
              'Traditional',
              ['==', ['get', 'Seasonality'], 'non_traditional'],
              'Non-Traditional',
              ['get', 'Seasonality'],
            ],
          },
        },
        { Urbanization: 'Urbanization' },
        {
          'Urbanization Classification': {
            key: 'Urbanization Classification',
            expression: [
              'case',
              ['==', ['get', 'Urbanization Classification'], 'urban'],
              'Urban',
              ['==', ['get', 'Urbanization Classification'], 'suburban'],
              'Suburban',
              ['==', ['get', 'Urbanization Classification'], 'rural'],
              'Rural',
              ['get', 'Urbanization Classification'],
            ],
          },
        },
        {
          'Sunday Traffic': {
            key: 'Sun_Vehicle_Traffic',
            expression: ['to-number', ['get', 'Sun Vehicle Traffic']],
            format: 'integer',
          },
        },
        {
          'Monday Traffic': {
            key: 'Mon_Vehicle_Traffic',
            expression: ['to-number', ['get', 'Mon Vehicle Traffic']],
            format: 'integer',
          },
        },
        {
          'Tuesday Traffic': {
            key: 'Tues_Vehicle_Traffic',
            expression: ['to-number', ['get', 'Tues Vehicle Traffic']],
            format: 'integer',
          },
        },
        {
          'Wednesday Traffic': {
            key: 'Wed_Vehicle_Traffic',
            expression: ['to-number', ['get', 'Wed Vehicle Traffic']],
            format: 'integer',
          },
        },
        {
          'Thursday Traffic': {
            key: 'Thurs_Vehicle_Traffic',
            expression: ['to-number', ['get', 'Thurs Vehicle Traffic']],
            format: 'integer',
          },
        },
        {
          'Friday Traffic': {
            key: 'Fri_Vehicle_Traffic',
            expression: ['to-number', ['get', 'Fri Vehicle Traffic']],
            format: 'integer',
          },
        },
        {
          'Saturday Traffic': {
            key: 'Sat_Vehicle_Traffic',
            expression: ['to-number', ['get', 'Sat Vehicle Traffic']],
            format: 'integer',
          },
        },
        {
          'Performance Index': {
            key: 'Performance_Index',
            expression: ['to-number', ['get', 'Performance Index']],
            format: 'integer',
          },
        },
      ],
    },
  },
  topgolf_seed_point: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topgolf: Sample Set',
    category: 'Fitness.Locations',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Topgolf',
      source: 'luketruitt1.arco',
      source_layer: 'topgolf_sample',
    },
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.transparent, Color.blue, Color.orange, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'Performance Classification'], 'top performer'],
        1,
        ['==', ['get', 'Performance Classification'], 'bottom performer'],
        3,
        2,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Brand: ', ['get', 'Brand']],
      fields: [
        {
          'Performance Classification': {
            key: 'Performance Classification',
            expression: [
              'case',
              ['==', ['get', 'Performance Classification'], 'top performer'],
              'Top Performer',
              ['==', ['get', 'Performance Classification'], 'bottom performer'],
              'Bottom Performer',
              'Standard Location',
            ],
          },
        },
        { CBSA: 'CBSA' },
        {
          'Urbanicity Type': {
            key: 'Urbanicity Type',
            expression: [
              'case',
              ['==', ['get', 'Urbanicity Type'], 'urban core (6)'],
              'Urban Core (6)',
              ['==', ['get', 'Urbanicity Type'], 'urban (5)'],
              'Urban (5)',
              ['==', ['get', 'Urbanicity Type'], 'metropolitan (4)'],
              'Metropolitan (4)',
              ['==', ['get', 'Urbanicity Type'], 'suburban (3)'],
              'Suburban (3)',
              ['==', ['get', 'Urbanicity Type'], 'in-town (2)'],
              'In-Town (2)',
              ['==', ['get', 'Urbanicity Type'], 'rural (1)'],
              'Rural (1)',
              ['get', 'Urbanicity Type'],
            ],
          },
        },
        {
          'Total Foot Traffic': {
            key: 'Total Foot Traffic',
            expression: ['to-number', ['get', 'Total Foot Traffic']],
            format: 'integer',
          },
        },
        {
          'Yearly Mean Foot Traffic': {
            key: 'Yearly Mean Foot Traffic',
            expression: ['to-number', ['get', 'Yearly Mean Foot Traffic']],
            format: 'integer',
          },
        },
        {
          'NTL Ranking': {
            key: 'NTL Ranking',
            expression: ['to-number', ['get', 'NTL Ranking']],
            format: 'integer',
          },
        },
        {
          'NTL Index': {
            key: 'NTL Index',
            expression: ['to-number', ['get', 'NTL Index']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'Market Ranking',
            expression: ['to-number', ['get', 'Market Ranking']],
            format: 'integer',
          },
        },
        {
          'Market Index': {
            key: 'Market Index',
            expression: ['to-number', ['get', 'Market Index']],
            format: 'integer',
          },
        },
        { 'Opened On': 'Opened On' },
        { 'Closed On': 'Closed On' },
        {
          Seasonality: {
            key: 'Seasonality',
            expression: [
              'case',
              ['==', ['get', 'Seasonality'], 'traditional'],
              'Traditional',
              ['==', ['get', 'Seasonality'], 'non_traditional'],
              'Non-Traditional',
              ['get', 'Seasonality'],
            ],
          },
        },
        {
          'Urbanization Classification': {
            key: 'Urbanization Classification',
            expression: [
              'case',
              ['==', ['get', 'Urbanization Classification'], 'urban'],
              'Urban',
              ['==', ['get', 'Urbanization Classification'], 'suburban'],
              'Suburban',
              ['==', ['get', 'Urbanization Classification'], 'rural'],
              'Rural',
              ['get', 'Urbanization Classification'],
            ],
          },
        },
        {
          'Performance Index': {
            key: 'Performance Index',
            expression: ['to-number', ['get', 'Performance Index']],
            format: 'integer',
          },
        },
      ],
    },
  },
  demand_ranking: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Texas Opportunity Zone: SFH Rental Demand Score',
    category: 'Housing.Analysis',
    class: 'housing',
    groupName: 'demographic_layers',

    sourceTileset: {
      label: 'Demand Ranking',
      source: 'luketruitt1.demand-ranking',
      source_layer: 'demand-ranking',
    },
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.red],
      threshold: [0, 1, 2],
      opacityStops: [0, 1, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'opp_zones'], 'True'],
          [
            '==',
            ['get', 'AUS_SAT Opp Zones Qualified Investment'],
            'Qualified',
          ],
        ],
        1,
        [
          'all',
          ['==', ['get', 'opp_zones'], 'True'],
          [
            '==',
            ['get', 'AUS_SAT Opp Zones Qualified Investment'],
            'Not Qualified',
          ],
        ],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
      boundLabels: ['Qualified', 'Not Qualified'],
    },
    dataConfig: {
      header: ['concat', 'Block Group: ', ['get', 'geo_id']],
      fields: [
        {
          'Demand/Supply Ratio': {
            key: 'total_demand_supply_ratio',
            format: 'ratio',
          },
        },
        {
          CBSA: {
            key: 'cbsa',
            expression: [
              'case',
              ['==', ['get', 'cbsa'], 0],
              '-',
              ['get', 'cbsa'],
            ],
          },
        },
        {
          'CBSA Ranking': {
            key: 'cbsa_ranking',
            expression: [
              'case',
              ['>', ['get', 'cbsa_ranking'], 0],
              ['get', 'cbsa_ranking'],
              '-',
            ],
          },
        },
        { 'Austin/San Antonio Area': 'AUS_SAT' },
        { 'Opportunity Zone': 'opp_zones' },
        {
          'CBSA Opportunity Zone Ranking': {
            key: 'cbsa_oz_ranking',
            expression: [
              'case',
              ['>', ['get', 'cbsa_oz_ranking'], 0],
              ['get', 'cbsa_oz_ranking'],
              '-',
            ],
          },
        },
        {
          'Austin/San Antonio Investment Status': {
            key: 'AUS_SAT Opp Zones Qualified Investment',
            expression: [
              'case',
              [
                '==',
                ['get', 'AUS_SAT Opp Zones Qualified Investment'],
                'Qualified',
              ],
              'Qualified',
              [
                '==',
                ['get', 'AUS_SAT Opp Zones Qualified Investment'],
                'Not Qualified',
              ],
              'Not Qualified',
              '-',
            ],
          },
        },
        {
          'Austin/San Antonio Qualified Ranking': {
            key: 'AUS_SAT Opp Zones Qualified Ranking',
            expression: [
              'case',
              ['>', ['get', 'AUS_SAT Opp Zones Qualified Ranking'], 0],
              ['get', 'AUS_SAT Opp Zones Qualified Ranking'],
              '-',
            ],
          },
        },
        { State: 'state' },
        {
          'State Ranking': {
            key: 'state_ranking',
            expression: [
              'case',
              ['>', ['get', 'state_ranking'], 0],
              ['get', 'state_ranking'],
              '-',
            ],
          },
        },
        {
          'State Opportunity Zone Ranking': {
            key: 'state_oz_ranking',
            expression: [
              'case',
              ['>', ['get', 'state_oz_ranking'], 0],
              ['get', 'state_oz_ranking'],
              '-',
            ],
          },
        },
        {
          'Texas Investment Status': {
            key: 'TX Opp Zones Qualified Investment',
            expression: [
              'case',
              ['==', ['get', 'TX Opp Zones Qualified Investment'], 'Qualified'],
              'Qualified',
              [
                '==',
                ['get', 'TX Opp Zones Qualified Investment'],
                'Not Qualified',
              ],
              'Not Qualified',
              '-',
            ],
          },
        },
        {
          'Texas Qualified Ranking': {
            key: 'TX Opp Zones Qualified Ranking',
            expression: [
              'case',
              ['>', ['get', 'TX Opp Zones Qualified Ranking'], 0],
              ['get', 'TX Opp Zones Qualified Ranking'],
              '-',
            ],
          },
        },
        {
          'Renter Demand': {
            key: 'Renter Demand',
            format: 'integer',
          },
        },
        {
          'Total Housing Supply': {
            key: 'Total Housing Supply',
            format: 'integer',
          },
        },
        {
          'Median Monthly Mortgage': {
            key: 'median_monthly_mortgage_payment',
            format: 'currency',
          },
        },
        {
          'Average Monthly Rent': {
            key: 'Average Monthly Rent',
            format: 'currency',
          },
        },
        {
          'Average Yearly Rent': {
            key: 'Average Yearly Rent',
            format: 'currency',
          },
        },
        { 'Buy or Rent': 'Buy or Rent' },
        {
          'Price to Rent Ratio': {
            key: 'Price_to_Rent_Ratio',
            format: 'ratio',
          },
        },
        {
          'Total Households': {
            key: 'Households Est CrYr',
            format: 'integer',
          },
        },
        {
          'Owner Occupied Housing': {
            key: 'Owner Occupied Housing CrYr',
            format: 'integer',
          },
        },
        {
          'Renter Occupied Housing': {
            key: 'Renter Occupied Housing CrYr',
            format: 'integer',
          },
        },
        {
          'Vacant Housing': {
            key: 'Vacant Housing CrYr',
            format: 'integer',
          },
        },
        {
          'Average Home Value': {
            key: 'Average Home Value CrYr',
            format: 'currency',
          },
        },
        {
          'Median Home Value': {
            key: 'Median Home Value CrYr',
            format: 'currency',
          },
        },
        { 'Data Vintage': 'datavintage' },
        { 'Last Updated': 'timestamp' },
      ],
    },
  },
  fitness_search_demand_v3: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '11/25/2024 — 12/25/2024 - Search Demand — Fitness',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'fitness_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 83.59, 762.72, 6098.02, 12165.84],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'fitness_demand_v3__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'fitness_demand_v3__date_range',
      fields: [
        {
          Impressions: {
            key: 'fitness_demand_v3__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'fitness_demand_v3__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'fitness_demand_v3__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  handw_core_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      '11/31/2024 — 12/31/2024 - Search Demand — Health & Wellness: Core',
    category: 'Healthcare.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'handw_core_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 119.24, 238.48, 792.9, 12116.46],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.darkPurple,
      ],
      expression: ['get', 'handwcore__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'handwcore__date_range',
      fields: [
        {
          Impressions: {
            key: 'handwcore__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'handwcore__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'handwcore__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  handw_iv_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      '12/03/2024 — 01/03/2025 - Search Demand — Health & Wellness: IV',
    category: 'Healthcare.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'handw_iv_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10.58, 21.15, 147.74, 4323.8],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.darkPurple,
      ],
      expression: ['get', 'handwiv__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'handwiv__date_range',
      fields: [
        {
          Impressions: {
            key: 'handwiv__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'handwiv__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'handwiv__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  handw_skin_health_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      '12/09/2024 — 01/09/2025 - Search Demand: Health & Wellness — Skin Health',
    category: 'Health and Wellness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'handw_skin_health_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 30.24, 60.48, 375.0, 6168.81],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.darkPurple,
      ],
      expression: ['get', 'handwskin__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'handwskin__date_range',
      fields: [
        {
          Impressions: {
            key: 'handwskin__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'handwskin__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'handwskin__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  handw_medical_HBOT_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      '12/13/2024 — 01/13/2025 - Search Demand: Health & Wellness — Medical HBOT',
    category: 'Health and Wellness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'handw_medical_HBOT_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10.02, 20.03, 79.78, 1115.77],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.darkPurple,
      ],
      expression: ['get', 'handwmedicalhbot__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'handwmedicalhbot__date_range',
      fields: [
        {
          Impressions: {
            key: 'handwmedicalhbot__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'handwmedicalhbot__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'handwmedicalhbot__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  handw_NAD_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      '12/07/2024 — 01/07/2025 - Search Demand: Health & Wellness — NAD+',
    category: 'Health and Wellness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'handw_NAD_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 24.63, 49.26, 257.92, 4346.65],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'handwnad__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'handwnad__date_range',
      fields: [
        {
          Impressions: {
            key: 'handwnad__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'handwnad__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'handwnad__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  handw_weight_mgmt_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      '12/15/2024 — 01/15/2025 - Search Demand: Health & Wellness — Weight Management',
    category: 'Health and Wellness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'handw_weight_mgmt_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 5.15, 10.3, 60.37, 793.96],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.darkPurple,
      ],
      expression: ['get', 'handwweightmgmt__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'handwweightmgmt__date_range',
      fields: [
        {
          Impressions: {
            key: 'handwweightmgmt__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'handwweightmgmt__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'handwweightmgmt__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  kidstrong_search_demand_v3: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '12/01/2024 — 01/01/2025 - Search Demand — KidStrong',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'kidstrong_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 21.09, 422.73, 1492.01],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'kidstrong_v3__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'kidstrong_v3__date_range',
      fields: [
        {
          Impressions: {
            key: 'kidstrong_v3__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'kidstrong_v3__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'kidstrong_v3__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  supermatt_pickup_delivery_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      '11/18/2024 — 12/18/2024 - Search Demand — Laundry Pickup & Delivery',
    category: 'Services.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'laundry_pickup_delivery_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 94.17, 757.49, 2156.27],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'laundry_pud__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'laundry_pud__date_range',
      fields: [
        {
          Impressions: {
            key: 'laundry_pud__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'laundry_pud__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'laundry_pud__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  supermatt_walkin_self_service_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      '11/23/2024 — 12/23/2024 - Search Demand — Laundry Walk-in & Self-Service',
    category: 'Services.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'laundry_walkin_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 18.87, 131.27, 1511.58, 4380.0],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'laundry_wiss__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'laundry_wiss__date_range',
      fields: [
        {
          Impressions: {
            key: 'laundry_wiss__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'laundry_wiss__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'laundry_wiss__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  pvolve_search_demand_v3: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '11/22/2024 — 12/22/2024 - Search Demand — Pvolve',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'pvolve_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 28.55, 230.6, 737.44],
      opacityStops: [0, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'pvolve_v3__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'pvolve_v3__date_range',
      fields: [
        {
          Impressions: {
            key: 'pvolve_v3__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'pvolve_v3__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'pvolve_v3__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  regrid_parcels: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Parcels - Regrid Sample (Fulton, ID + Faulkner, AR)',
    category: 'Miscellaneous.Property',
    groupName: 'boundary_layers',
    isBoundary: true,
    sourceTileset: {
      label: 'Regrid Parcels',
      source: 'luketruitt1.regrid_parcels',
      source_layer: 'regrid_parcels',
    },
    styleConfig: {
      colors: [Color.transparent],
      fallback: 0,
      opacityStops: [0, 1],
    },
    dataConfig: {
      header: 'parcelnumb',
      fields: [
        {
          'Parcel Details': 'parcel_id',
        },
        {
          Location: {
            expression: [
              'concat',
              ['coalesce', ['get', 'address'], ''],
              ' ',
              ['coalesce', ['get', 'address2'], ''],
              '\n',
              ['coalesce', ['get', 'scity'], ''],
              ', ',
              ['upcase', ['coalesce', ['get', 'state2'], '']],
              ' ',
              ['coalesce', ['get', 'szip'], ''],
            ],
          },
        },
        {
          Coordinates: {
            expression: [
              'concat',
              ['number', ['get', 'lat'], 6],
              ', ',
              ['number', ['get', 'lon'], 6],
            ],
          },
        },
        {
          'Property Size': {
            expression: [
              'concat',
              ['number', ['get', 'll_gisacre'], 2],
              ' acres (',
              ['number', ['get', 'll_gissqft'], 0],
              ' sq ft)',
            ],
          },
        },
        {
          'Building Count': {
            key: 'll_bldg_co',
            format: 'integer',
          },
        },
        {
          'Year Built': {
            key: 'yearbuilt',
            format: 'integer',
          },
        },
        {
          'Last Sale': {
            expression: [
              'concat',
              [
                'coalesce',
                ['format-number', ['get', 'saleprice'], '$,d'],
                'N/A',
              ],
              ' on ',
              ['coalesce', ['date', ['get', 'saledate'], 'MM/dd/yyyy'], 'N/A'],
            ],
          },
        },
        {
          'Property Values': {
            expression: [
              'concat',
              'Land: ',
              ['coalesce', ['format-number', ['get', 'landval'], '$,d'], 'N/A'],
              '\nImprovements: ',
              [
                'coalesce',
                ['format-number', ['get', 'improvval'], '$,d'],
                'N/A',
              ],
              '\nTotal: ',
              ['coalesce', ['format-number', ['get', 'parval'], '$,d'], 'N/A'],
            ],
          },
        },
        {
          'Census Info': {
            expression: [
              'concat',
              'Tract: ',
              ['coalesce', ['get', 'census_tra'], 'N/A'],
              '\nBlock: ',
              ['coalesce', ['get', 'census_b_1'], 'N/A'],
            ],
          },
        },
        {
          'Opportunity Zone': 'qoz',
        },
      ],
    },
  },
  ...powerPlantsLayers,
  terrain_layer: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Terrain',
    category: 'Infrastructure.Geography',
    groupName: 'boundary_layers',
    sourceTileset: {
      label: 'Mapbox Terrain',
      source: 'mapbox.mapbox-terrain-v2',
      source_layer: 'contour',
    },
    styleConfig: {
      colors: [Color.transparent, Color.darkBlue, Color.lightBlue],
      opacityStops: [0, 0.8, 0.8],
      threshold: [0, 1, 10],
      expression: ['id'],
      hideBoundValues: true,
      boundLabels: ['Low Elevation', 'High Elevation'],
    },
  },
};
