import { Box, Typography } from '@mui/material';
import { CustomPin, plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';
import type mapboxgl from 'mapbox-gl';
import { memo, useEffect, useRef } from 'react';
import { Marker } from 'react-map-gl';
import { useCustomPinMarker } from '~/src/features/custom-pins/hooks/useCustomPinMarker';
import { usePinsContextMenu } from '~/src/features/custom-pins/hooks/usePinsContextMenu';

interface CustomPinMarkerProps {
  pin: CustomPin;
}

const CustomPinMarker = memo(function CustomPinMarker({
  pin,
}: CustomPinMarkerProps) {
  const ref = useRef<mapboxgl.Marker>(null);
  const customPinMethods = plotrMultiplayerData.methods?.pins;
  const handleContextMenu = usePinsContextMenu(pin);

  const { pinURL, scale, textStyle, handlePinClick } = useCustomPinMarker(pin);

  useEffect(() => {
    const node = ref.current;
    if (node != null) {
      node.getElement().addEventListener('contextmenu', handleContextMenu);
    }

    return () => {
      if (node != null) {
        node.getElement().removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, [handleContextMenu]);

  if (!customPinMethods) return null;

  return (
    <Marker
      ref={ref}
      key={pin.id}
      longitude={pin.pos.lng}
      latitude={pin.pos.lat}
      onClick={handlePinClick}
      anchor="bottom"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          transform: `scale(${scale})`,
          transformOrigin: 'center bottom',
        }}
      >
        <img
          style={{ width: '5rem', height: 'auto' }}
          src={pinURL}
          alt="custom pin"
        />
        <Typography variant="body1" sx={textStyle}>
          {pin.label}
        </Typography>
      </Box>
    </Marker>
  );
});

export default CustomPinMarker;
