import fiveYearProjectionLayers from '~/src/global/constants/5yrIncProjectionLayers';
import { Color } from '~/src/global/constants/colors';
import historicalIncomeLayers from '~/src/global/constants/historicalIncomeLayers';
import { squareMetersPerSquareMile } from '~/src/global/constants/measurementSystems';
import { LayerConfig, LayerType } from '../components/MapLayersMenu';

export const LifestyleSegmentationColors = {
  Undefined: 'rgba(0, 0, 0, 0)',
  'Empire Builders': '#E57373',
  'Grand Masters': '#E57373',
  'Marquis Class': '#E57373',
  'American Knights': '#E57373',
  'Urban Squires': '#E57373',
  Regents: '#E57373',
  'Charmed Life': '#E57373',
  'Sitting Pretty': '#FF7043',
  'Kindred Spirit': '#FF7043',
  'Middle of the Road': '#FF7043',
  'White Collar Status': '#FF7043',
  'Blue Collar Starts': '#FF7043',
  'Social Whirls': '#FFEB3B',
  'Managing Business': '#FFEB3B',
  'Nest Builders': '#FFEB3B',
  'Gainfully Employed': '#FFEB3B',
  Strapped: '#FFEB3B',
  'Gray Eminence': '#9CCC65',
  'Fall Years': '#9CCC65',
  'Still in the Game': '#9CCC65',
  Gurus: '#26A69A',
  Wizards: '#26A69A',
  Apprentices: '#26A69A',
  'Hard Act to Follow': '#42A5F5',
  'SM Seeks SF': '#42A5F5',
  'Solo Acts': '#42A5F5',
  'Down but Not Out': '#42A5F5',
  'Urban Moms Dads': '#5C6BC0',
  'Apron Strings Hard Hats': '#5C6BC0',
  'Solemn Widows Widowers': '#5C6BC0',
  'Educated Earners': '#8E24AA',
  'Suburban Singles': '#8E24AA',
  'Proud Parent': '#8E24AA',
  'Bonds and Babies': '#F06292',
  'Great Generations': '#F06292',
  'Couples with Capital': '#F06292',
  'Kith and Kin': '#F06292',
  'Sublime Suburbia': '#F06292',
  'Stocks and Scholars': '#78909C',
  'Marmalade and Money': '#78909C',
  'Stately Suburbs': '#78909C',
  'Country Villas': '#FFAB91',
  'Pastoral Vistas': '#FFAB91',
  'Terra Firma': '#FFAB91',
  'Stock in Trade': '#FFAB91',
  'Rough and Ready': '#FFAB91',
  'The Outback': '#FFAB91',
  Cornucopia: '#FFAB91',
  'Land Barons': '#9E9D24',
  'Fertile Acres': '#9E9D24',
  Breadbasket: '#9E9D24',
  'Farmers Circle': '#9E9D24',
  'Crops and Tractors': '#9E9D24',
  'Black Gentry': '#00BCD4',
  'Standing Tall': '#00BCD4',
  'Still Standing': '#00BCD4',
  'Pushing Through': '#00BCD4',
  'Between Jobs': '#00BCD4',
  'Anos de Quincenera': '#9575CD',
  'Los Padres': '#9575CD',
  'Los Novios': '#9575CD',
  'Los Padrinos': '#9575CD',
  'Los Solteros': '#9575CD',
  'Los Trabajadores': '#9575CD',
  'Golden Heritage': '#A1887F',
  'East Meets West': '#A1887F',
  'Group Quarters': '#A1887F',
  Doublewides: '#A1887F',
  Centurions: '#A1887F',
  'Legacy Years': '#A1887F',
  Collegian: '#A1887F',
};

const currentYear = new Date().getFullYear();

// HACK: I don't like that this has to use trafficLayerIds as a parameter, but for now it'll do.
export default function getMapLayerDefinitions(
  trafficLayerIds: string[]
): Record<string, LayerConfig> {
  return {
    mfh_apartment_buildings: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Apartment Buildings - Multi-Family Apartments',
      category: 'Population.Income',
      groupName: 'demographic_layers',
      class: 'housing',
      sourceTileset: {
        label: 'Multi-Family Apartments',
        source: 'luketruitt1.mfh_apartments',
        source_layer: 'mfh_apartments',
      },
      styleConfig: {
        threshold: [0, 1],
        colors: [Color.red, Color.darkGreen],
        fallback: 0,
        expression: ['case', ['has', 'year_built'], 1, 0],
        opacityStops: [0, 1],
      },
      dataConfig: {
        header: 'name',
        fields: [
          {
            Age: {
              expression: [
                'case',
                ['>', ['get', 'year_built'], 0],
                [
                  'concat',
                  ['-', currentYear, ['get', 'year_built']],
                  ' (',
                  ['get', 'year_built'],
                  ')',
                ],
                'Unknown',
              ],
            },
          },
          {
            Units: {
              expression: ['get', 'units'],
            },
          },
          {
            'Rent Range': {
              expression: [
                'case',
                ['has', 'min_price'],
                [
                  'concat',
                  '$',
                  ['get', 'min_price'],
                  ' - ',
                  '$',
                  ['get', 'max_price'],
                ],
                'Unknown',
              ],
            },
          },
          {
            'Avg Rent': {
              expression: [
                'concat',
                '$',
                ['/', ['+', ['get', 'min_price'], ['get', 'max_price']], 2],
              ],
            },
          },
          {
            'Community Amenities': {
              expression: [
                'case',
                ['has', 'community_amenities'],
                ['get', 'community_amenities'],
                '[]',
              ],
              format: 'chips',
            },
          },
          {
            'Apartment Features': {
              expression: [
                'case',
                ['has', 'apartment_features'],
                ['get', 'apartment_features'],
                '[]',
              ],
              format: 'chips',
            },
          },
        ],
      },
    },
    bg_urbanicity: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Block Group Urbanicity',
      category: 'Population.Urbanicity',
      groupName: 'demographic_layers',
      class: 'Urbanicity',
      sourceTileset: {
        label: 'Block Group Urbanicity',
        source: 'luketruitt1.bg_urbanicity',
        source_layer: 'bg_urbanicity',
      },
      styleConfig: {
        colors: [
          Color.transparent,
          Color.rural,
          Color.inTown,
          Color.suburban,
          Color.metropolitan,
          Color.urban,
          Color.urbanCore,
        ],
        threshold: [0, 1, 2, 3, 4, 5, 6],
        expression: ['get', 'urbanicity'],
        fallback: 0,
        opacityStops: [0, 1, 1, 1, 1, 1, 1],
        boundLabels: ['Rural', 'Urban Core'],
        hideBoundValues: true,
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            'Urbanization Classification': {
              expression: [
                'match',
                ['get', 'urbanicity'],
                0,
                'Unknown',
                1,
                'Rural',
                2,
                'In-Town',
                3,
                'Suburban',
                4,
                'Metropolitan',
                5,
                'Urban',
                6,
                'Urban Core',
                'Unknown',
              ],
            },
          },
          {
            'Urbanization Type': {
              expression: [
                'case',
                ['<=', ['get', 'urbanicity'], 4],
                'Suburban',
                ['>=', ['get', 'urbanicity'], 5],
                'Urban',
                'Unknown',
              ],
            },
          },
          {
            Seasonality: {
              expression: [
                'match',
                ['get', 'seasonality'],
                'traditional',
                'Traditional',
                'non_traditional',
                'Non-traditional',
                ['get', 'seasonality'],
              ],
            },
          },
        ],
      },
    },
    bg_seasonality: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Seasonality',
      category: 'Population.Urbanicity',
      groupName: 'demographic_layers',
      class: 'Seasonality',
      sourceTileset: {
        label: 'Block Group Urbanicity',
        source: 'luketruitt1.bg_urbanicity',
        source_layer: 'bg_urbanicity',
      },
      styleConfig: {
        colors: [Color.transparent, Color.darkGreen, Color.orange],
        threshold: [0, 1, 2],
        expression: [
          'match',
          ['get', 'seasonality'],
          'traditional',
          1,
          'non_traditional',
          2,
          0,
        ],
        fallback: 0,
        opacityStops: [0, 1, 1],
        boundLabels: ['Traditional', 'Non-traditional'],
        hideBoundValues: true,
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            Seasonality: {
              expression: [
                'match',
                ['get', 'seasonality'],
                'traditional',
                'Traditional',
                'non_traditional',
                'Non-traditional',
                'Unknown',
              ],
            },
          },
        ],
      },
    },
    taid_urbanicity: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'TAID - Trade Area ID Urbanicity',
      category: 'Population.Urbanicity',
      groupName: 'demographic_layers',
      class: 'Urbanicity',
      sourceTileset: {
        label: 'TAID Urbanicity',
        source: 'luketruitt1.TAID',
        source_layer: 'TAID',
      },
      styleConfig: {
        imageName: 'geometry-point-pin-sdf',
        colors: [
          Color.transparent,
          Color.rural,
          Color.inTown,
          Color.suburban,
          Color.metropolitan,
          Color.urban,
          Color.urbanCore,
        ],
        threshold: [0, 1, 2, 3, 4, 5, 6],
        expression: ['get', 'urbanization_classification'],
        fallback: 0,
        opacityStops: [0, 1, 1, 1, 1, 1, 1],
        boundLabels: ['Rural', 'Urban Core'],
        hideBoundValues: true,
      },
      dataConfig: {
        header: [
          'concat',
          'TAID: ',
          ['to-string', ['get', 'id']],
          ' (',
          ['get', 'taid_city'],
          ', ',
          ['get', 'taid_state'],
          ')',
        ],
        fields: [
          // **Location Details**
          { Address: 'taid_address' },
          { City: 'taid_city' },
          { State: 'taid_state' },
          {
            'ZIP Code': {
              expression: ['to-string', ['get', 'taid_zip_code']],
            },
          },
          { CBSA: 'cbsa' },

          // **Urbanization Information**
          {
            'Urbanization Type': {
              expression: [
                'case',
                ['<=', ['get', 'urbanization_classification'], 4],
                'Suburban',
                ['>=', ['get', 'urbanization_classification'], 5],
                'Urban',
                'Unknown',
              ],
            },
          },
          {
            'Urbanization Classification': {
              expression: [
                'match',
                ['get', 'urbanization_classification'],
                0,
                'Unknown',
                1,
                'Rural',
                2,
                'In-Town',
                3,
                'Suburban',
                4,
                'Metropolitan',
                5,
                'Urban',
                6,
                'Urban Core',
                'Unknown',
              ],
            },
          },

          // **Demographic Data**
          { 'Population Density': 'pop_density' },
          { 'Workforce Density': 'workforce_density' },

          // **Additional Information**
          {
            Seasonality: {
              expression: [
                'match',
                ['get', 'seasonality'],
                'traditional',
                'Traditional',
                'non_traditional',
                'Non-traditional',
                ['get', 'seasonality'],
              ],
            },
          },
        ],
      },
    },
    bg_total_population: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Population',
      category: 'Population',
      groupName: 'demographic_layers',
      class: 'population',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [0, 3000, 10000, 60000],
        colors: [
          Color.white,
          Color.lightGray,
          Color.lightGreen,
          Color.darkGreen,
        ],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              ['/', ['get', 'total_population'], ['get', 'area']],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        opacityStops: [0, 1, 1, 1],
        unit: 'people / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          { Population: 'total_population' },
          {
            'People / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    ['/', ['get', 'total_population'], ['get', 'area']],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
            },
          },
        ],
      },
    },
    seasonal_population: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Seasonal Population',
      category: 'Population.Seasonal',
      groupName: 'demographic_layers',
      class: 'population',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [0, 10, 100, 1000],
        colors: [Color.lightYellow, Color.yellow, Color.orange, Color.red],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              ['/', ['get', 'seasonal_population'], ['get', 'area']],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        opacityStops: [0, 1, 1, 1],
        unit: 'people / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          { Population: 'seasonal_population' },
          {
            'People / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    ['/', ['get', 'seasonal_population'], ['get', 'area']],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
            },
          },
        ],
      },
    },
    bg_population_growth_5yr: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - 5-Year Projected Population Change',
      permission: 'read:insight_pop_growth_5yr',
      category: 'Population.Change',
      groupName: 'demographic_layers',
      class: 'population',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [-2000, 0, 1000, 10000],
        colors: [Color.lightRed, Color.white, Color.lightBlue, Color.blue],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              [
                '/',
                [
                  '-',
                  ['get', 'population_growth_5yr'],
                  ['get', 'total_population'],
                ],
                ['get', 'area'],
              ],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        opacityStops: [1, 0, 1, 1],
        format: 'raw_change',
        unit: 'people / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            '5-Year Population Change': {
              expression: [
                '-',
                ['get', 'population_growth_5yr'],
                ['get', 'total_population'],
              ],
              format: 'raw_change',
            },
          },
          {
            '5-Year Change in People / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    [
                      '/',
                      [
                        '-',
                        ['get', 'population_growth_5yr'],
                        ['get', 'total_population'],
                      ],
                      ['get', 'area'],
                    ],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
              format: 'raw_change',
            },
          },
        ],
      },
    },
    bg_population_growth_10yr: {
      type: LayerType.Insights,
      featureType: 'polygon',
      permission: 'read:insight_pop_growth_10yr',
      category: 'Population.Growth',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      displayName: 'Block Group - 10 Year Projected Population Change',
      groupName: 'demographic_layers',
      class: 'population',
      styleConfig: {
        threshold: [-2000, 0, 1000, 10000],
        colors: [Color.lightRed, Color.white, Color.lightBlue, Color.blue],
        opacityStops: [1, 0, 1, 1],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              [
                '/',
                [
                  '-',
                  ['get', 'population_growth_10yr'],
                  ['get', 'total_population'],
                ],
                ['get', 'area'],
              ],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        format: 'raw_change',
        unit: 'people / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            '10 Year Projected Population Change': {
              expression: [
                '-',
                ['get', 'population_growth_10yr'],
                ['get', 'total_population'],
              ],
              format: 'raw_change',
            },
          },
          {
            '10-Year Change in People / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    [
                      '/',
                      [
                        '-',
                        ['get', 'population_growth_10yr'],
                        ['get', 'total_population'],
                      ],
                      ['get', 'area'],
                    ],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
              format: 'raw_change',
            },
          },
        ],
      },
    },
    bg_population_growth_5yr_percent: {
      type: LayerType.Insights,
      featureType: 'polygon',
      category: 'Population.Growth',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      displayName:
        'Block Group - 5 Year Projected Population Change (percentage)',
      groupName: 'demographic_layers',
      class: 'population',
      styleConfig: {
        threshold: [-1, -0.5, 0, 0.5, 1, 1.5],
        colors: [
          Color.red,
          Color.lightRed,
          Color.white,
          Color.lightBlue,
          Color.blue,
          Color.darkBlue,
        ],
        opacityStops: [1, 1, 0, 1, 1, 1],
        fallback: 0,
        expression: [
          'case',
          [
            'any',
            ['==', ['get', 'total_population'], 0],
            ['==', ['get', 'population_growth_5yr'], 0],
          ],
          0,
          [
            '-',
            [
              '/',
              ['get', 'population_growth_5yr'],
              ['get', 'total_population'],
            ],
            1,
          ],
        ],
        format: 'percent',
        unit: '%',
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            '5 Year Projected Population Change (percentage)': {
              expression: [
                'case',
                [
                  'any',
                  ['==', ['get', 'total_population'], 0],
                  ['==', ['get', 'population_growth_5yr'], 0],
                ],
                0,
                [
                  '-',
                  [
                    '/',
                    ['get', 'population_growth_5yr'],
                    ['get', 'total_population'],
                  ],
                  1,
                ],
              ],
              format: 'percent',
            },
          },
        ],
      },
    },
    bg_population_growth_10yr_percent: {
      type: LayerType.Insights,
      featureType: 'polygon',
      category: 'Population.Growth',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      displayName:
        'Block Group - 10 Year Projected Population Change (percentage)',
      groupName: 'demographic_layers',
      class: 'population',
      styleConfig: {
        threshold: [-1, -0.5, 0, 0.5, 1, 1.5],
        colors: [
          Color.red,
          Color.lightRed,
          Color.white,
          Color.lightBlue,
          Color.blue,
          Color.darkBlue,
        ],
        opacityStops: [1, 1, 0, 1, 1, 1],
        fallback: 0,
        expression: [
          'case',
          [
            'any',
            ['==', ['get', 'total_population'], 0],
            ['==', ['get', 'population_growth_10yr'], 0],
          ],
          0,
          [
            '-',
            [
              '/',
              ['get', 'population_growth_10yr'],
              ['get', 'total_population'],
            ],
            1,
          ],
        ],
        format: 'percent',
        unit: '%',
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            '10 Year Projected Population Change (percentage)': {
              expression: [
                'case',
                [
                  'any',
                  ['==', ['get', 'total_population'], 0],
                  ['==', ['get', 'population_growth_10yr'], 0],
                ],
                0,
                [
                  '-',
                  [
                    '/',
                    ['get', 'population_growth_10yr'],
                    ['get', 'total_population'],
                  ],
                  1,
                ],
              ],
              format: 'percent',
            },
          },
        ],
      },
    },
    bg_median_home_value: {
      type: LayerType.Insights,
      featureType: 'polygon',
      permission: 'read:insight_median_home_value',
      category: 'Population.Income',
      mergeGroup: 'bg_median_home_value',
      sourceTileset: {
        label: 'Block Groups',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      displayName: 'Block Group - Median Home Value',
      groupName: 'demographic_layers',
      class: 'housing',
      styleConfig: {
        colors: [Color.white, Color.yellow, Color.red, Color.purple],
        threshold: [0, 400000, 600000, 1000000],
        expression: ['get', 'median_home_value'],
        fallback: 0,
        format: 'dollars',
        opacityStops: [0, 1, 1, 1],
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            'Median Home Value': {
              key: 'median_home_value',
              format: 'dollars',
            },
          },
        ],
      },
    },
    bg_median_household_income: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Median Household Income',
      category: 'Population.Income',
      groupName: 'demographic_layers',
      mergeGroup: 'median_household_income',
      class: 'housing',
      sourceTileset: {
        label: 'Block Groups',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [40000, 80000, 120000, 200000],
        colors: [Color.lightBlue, Color.white, Color.orange, Color.purple],
        expression: ['get', 'median_household_income'],
        fallback: 80000,
        opacityStops: [1, 0, 1, 1],
        format: 'dollars',
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            'Median Household Income': {
              key: 'median_household_income',
              format: 'dollars',
            },
          },
        ],
      },
      permission: 'read:insight_median_hhi',
    },
    block_group_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Boundaries',
      category: 'Miscellaneous.Search American',
      groupName: 'boundary_layers',
      isBoundary: true,
      sourceTileset: {
        label: 'Block Groups',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        colors: [Color.transparent],
      },
      dataConfig: {
        header: 'id',
      },
    },
    zip_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Zipcode - Boundaries',
      category: 'Miscellaneous.Search American',
      groupName: 'boundary_layers',
      isBoundary: true,
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        colors: [Color.transparent],
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
      },
    },
    zip_median_home_value: {
      type: LayerType.Insights,
      featureType: 'polygon',
      permission: 'read:insight_median_home_value',
      category: 'Population.Income',
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: 'Zipcode - Median Home Value',
      groupName: 'demographic_layers',
      class: 'housing',
      mergeGroup: 'bg_median_home_value',
      styleConfig: {
        idLabelFlag: true,
        colors: [Color.white, Color.yellow, Color.red, Color.purple],
        threshold: [0, 400000, 600000, 1000000],
        expression: ['get', 'median_home_value'],
        fallback: 0,
        format: 'dollars',
        opacityStops: [0, 1, 1, 1],
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
        fields: [
          {
            'Median Home Value': {
              key: 'median_home_value',
              format: 'dollars',
            },
          },
        ],
      },
    },
    zip_median_household_income: {
      type: LayerType.Insights,
      featureType: 'polygon',
      category: 'Population.Income',
      permission: 'read:insight_median_hhi',
      mergeGroup: 'median_household_income',
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: 'Zipcode - Median Household Income',
      groupName: 'demographic_layers',
      class: 'income',
      styleConfig: {
        idLabelFlag: true,
        threshold: [40000, 80000, 120000, 200000],
        colors: [Color.lightBlue, Color.white, Color.orange, Color.purple],
        fallback: 80000,
        expression: ['get', 'household_income'],
        opacityStops: [1, 0, 1, 1],
        format: 'dollars',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
        fields: [
          {
            'Median Household Income': {
              key: 'household_income',
              format: 'dollars',
            },
          },
        ],
      },
    },
    zip_owner_occ_housing: {
      type: LayerType.Insights,
      featureType: 'polygon',
      category: 'Population.Income',
      permission: 'read:owner_occupied_housing',
      displayName: 'Zipcode - Owner Occupied Housing',
      groupName: 'demographic_layers',
      class: 'housing',
      styleConfig: {
        idLabelFlag: true,
        colors: [
          Color.lightYellow,
          Color.darkYellow,
          Color.orange,
          Color.darkOrange,
          Color.red,
        ],
        threshold: [0, 0.877, 0.939, 0.97, 1],
        expression: ['get', 'owner_occupancy'],
        fallback: 0,
        opacityStops: [0, 0.25, 1, 1, 1],
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
        fields: [
          {
            'Owner Occupied Housing': {
              key: 'owner_occupancy',
              format: 'percent',
            },
          },
        ],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
    },
    cbsa_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'CBSA - Boundaries',
      category: 'Miscellaneous.Search American',
      groupName: 'boundary_layers',
      isBoundary: true,
      sourceTileset: {
        label: 'CBSA',
        source: 'luketruitt1.cbsa',
        source_layer: 'cbsa',
      },
      styleConfig: { colors: [Color.transparent] },
      // FIXME: the names in this CBSA tileset are completely wrong, like with Waco TX being named "Palatka FL". They're all wrong. *facepalm*
      // dataConfig: { header: 'name', fields: [] },
    },
    city_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Cities - Boundaries',
      category: 'Miscellaneous.Search American',
      groupName: 'boundary_layers',
      isBoundary: true,
      sourceTileset: {
        label: 'Cities',
        source: 'luketruitt1.cities-2024',
        source_layer: 'cities-2024',
      },
      styleConfig: { colors: [Color.transparent] },
      dataConfig: { header: 'city_id', fields: [] },
    },
    county_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Counties - Boundaries',
      category: 'Miscellaneous.Search American',
      groupName: 'boundary_layers',
      isBoundary: true,
      sourceTileset: {
        label: 'Counties',
        source: 'luketruitt1.counties',
        source_layer: 'counties',
      },
      styleConfig: { colors: [Color.transparent] },
      dataConfig: { header: 'NAME', fields: [] },
    },
    neighborhood_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Neighborhood - Boundaries',
      category: 'Miscellaneous.Search American',
      groupName: 'boundary_layers',
      isBoundary: true,
      sourceTileset: {
        label: 'Neighborhood',
        source: 'luketruitt1.neighborhood',
        source_layer: 'neighborhood',
      },
      styleConfig: {
        colors: [Color.transparent],
      },
      dataConfig: {
        header: ['get', 'Name'],
        fields: [
          { city: { fieldName: 'City', expression: ['get', 'City'] } },
          { state: { fieldName: 'State', expression: ['get', 'State'] } },
        ],
      },
    },
    gentrification_thematic: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Tract - Gentrification Thematic',
      category: 'Population.Demographics',
      groupName: 'demographic_layers',
      permission: 'read:layer_gentrification_thematic',
      sourceTileset: {
        label: 'Gentrification Thematic',
        source: 'luketruitt1.gentrification_thematic',
        source_layer: 'gentrification_thematic',
      },
      styleConfig: {
        colors: [Color.blue],
      },
      dataConfig: {
        header: 'id',
      },
    },
    traffic_volume: {
      type: LayerType.Enterprise,
      featureType: 'line',
      displayName: 'Traffic Volume Heatmap',
      component: { layerIds: trafficLayerIds },
      groupName: 'traffic_layers',
      styleConfig: {
        threshold: [],
        colors: [
          'rgba(241, 197, 204, .1)',
          'rgba(241, 197, 204, .5)',
          '#f1c5cc',
          '#c76bb3',
          '#950097',
        ],
        unit: 'trips',
      },
      dataConfig: {
        header: 'streetlightId',
      },
      permission: 'read:traffic',
      category: 'Miscellaneous.Traffic Volume',
    },
    lifestyle_segmentation: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Lifestyle Segmentation - Top Psychographic Profiles',
      category: 'Lifestyle.Psychographic',
      groupName: 'psychographic_layers',
      permission: 'read:demographic_lifestyle_segmentation',
      sourceTileset: {
        label: 'Lifestyle Segmentation',
        source: 'luketruitt1.lifestyle-segmentation_top_three',
        source_layer: 'lifestyle-segmentation_top_three',
      },
      styleConfig: {
        colors: Object.values(LifestyleSegmentationColors),
        threshold: Object.keys(LifestyleSegmentationColors),
        expression: ['get', 'ls_1'],
        fallback: 'Undefined',
        boundLabels: ['Creme de la Creme', 'Specialties'],
        hideBoundValues: true,
        borderColor: 'rgba(255, 255, 255, 1)',
        borderWidth: [
          'interpolate',
          ['exponential', 2],
          ['zoom'],
          1,
          0.2,
          16,
          10,
        ],
      },
      dataConfig: {
        header: [
          'concat',
          'Top Psychographic Profiles',
          ' (',
          ['get', 'id'],
          '):',
        ],
        fields: [
          {
            'Top 1': {
              fieldName: ['get', 'ls_1'],
              expression: [
                'concat',
                ['round', ['*', ['get', 'ls_1_pc'], ['get', 'total']]],
                ' (',
                ['round', ['*', ['get', 'ls_1_pc'], 100]],
                '%)',
              ],
              removeNullable: true,
            },
          },
          {
            'Top 2': {
              fieldName: ['get', 'ls_2'],
              expression: [
                'concat',
                ['round', ['*', ['get', 'ls_2_pc'], ['get', 'total']]],
                ' (',
                ['round', ['*', ['get', 'ls_2_pc'], 100]],
                '%)',
              ],
              removeNullable: true,
            },
          },
        ],
      },
    },
    ...historicalIncomeLayers,
    ...fiveYearProjectionLayers,
  };
}
