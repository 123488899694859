import {
  InsightLayerConfig,
  LayerConfig,
  LayerType,
} from '~/src/features/dynamic-map-controller/components/MapLayersMenu';
import { Color } from './colors';

const basePowerPlantConfig: InsightLayerConfig = {
  type: LayerType.Insights,
  featureType: 'point',
  category: 'Miscellaneous.Traffic Volume',
  groupName: 'custom_layers',
  displayName: 'Power Plants',
  mergeGroup: 'power_plants',
  styleConfig: {
    imageName: 'geometry-point-pin-sdf',
    colors: [Color.transparent],
    threshold: [0],
    opacityStops: [0],
    expression: ['case', ['has', 'Plant_Code'], 1, 0],
    fallback: 0,
  },
  sourceTileset: {
    label: 'Power Plants',
    source: 'luketruitt1.power_plants',
    source_layer: 'power_plants',
  },
  dataConfig: {
    header: ['get', 'Plant_Name'],
    fields: [
      { 'Plant Code': 'Plant_Code' },
      { 'Utility Name': 'Utility_Name' },
      { Sector: 'sector_name' },
      { Address: 'Street_Address' },
      { City: 'City' },
      { County: 'County' },
      { State: 'State' },
      { ZIP: 'Zip' },
      { 'Primary Source': 'PrimSource' },
      { 'Source Description': 'source_desc' },
      { Technology: 'tech_desc' },
      { 'Total Capacity (MW)': 'Total_MW' },
      {
        Coordinates: {
          expression: [
            'concat',
            ['number', ['get', 'Latitude'], 6],
            ', ',
            ['number', ['get', 'Longitude'], 6],
          ],
        },
      },
    ],
  },
};

const powerSourceConfigs = {
  petroleum: { color: Color.brown, displayName: 'Petroleum Power Plants' },
  hydroelectric: {
    color: Color.blue,
    displayName: 'Hydroelectric Power Plants',
  },
  'natural gas': {
    color: Color.orange,
    displayName: 'Natural Gas Power Plants',
  },
  nuclear: { color: Color.purple, displayName: 'Nuclear Power Plants' },
  coal: { color: Color.black, displayName: 'Coal Power Plants' },
  'pumped storage': {
    color: Color.lightBlue,
    displayName: 'Pumped Storage Power Plants',
  },
  geothermal: { color: Color.green, displayName: 'Geothermal Power Plants' },
  wind: { color: Color.teal, displayName: 'Wind Power Plants' },
  biomass: { color: Color.darkGreen, displayName: 'Biomass Power Plants' },
  batteries: { color: Color.gray, displayName: 'Battery Storage Facilities' },
  other: { color: Color.red, displayName: 'Other Power Plants' },
  solar: { color: Color.yellow, displayName: 'Solar Power Plants' },
};

const powerPlantsLayers: Record<string, LayerConfig> = {
  power_plants_all: {
    ...basePowerPlantConfig,
    displayName: 'All Power Plants',
    mergeGroup: 'power_plants',
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [
        Color.transparent,
        ...Object.values(powerSourceConfigs).map((config) => config.color),
      ],
      threshold: Array.from({ length: 13 }, (_, i) => i),
      opacityStops: Array(13).fill(1),
      expression: [
        'match',
        ['get', 'PrimSource'],
        ...Object.keys(powerSourceConfigs).flatMap((source) => [
          source,
          Object.keys(powerSourceConfigs).indexOf(source) + 1,
        ]),
        0,
      ],
      fallback: 0,
    },
  },
};

// Generate individual layers for each power source
Object.entries(powerSourceConfigs).forEach(([source, config], _index) => {
  powerPlantsLayers[`power_plants_${source.replace(/\s+/g, '_')}`] = {
    ...basePowerPlantConfig,
    displayName: config.displayName,
    filter: ['==', ['get', 'PrimSource'], source],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.transparent, config.color],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: ['case', ['has', 'Plant_Code'], 1, 0],
      fallback: 0,
    },
  };
});

export default powerPlantsLayers;
