import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { Box, Card, Divider, Typography } from '@mui/material';
import { SimilarityDataConfig } from '~/src/constants';
import { formatValue } from '~/src/global/helpers/formatValue';
import { MultipleSimilarityCardTable } from './MultipleSimilarityCardTable';
import { SimilaritySubInsight } from './types';

export function MultipleSimilarityCard({
  icon: Icon,
  title,
  similarity,
  subInsights,
  variance,
  ring,
  qualified,
}: SimilaritySubInsight) {
  const { ICON_SIZE } = SimilarityDataConfig;
  const similarityObject = {
    title: 'Similarity',
    valueType: 'percentage' as const,
    value: similarity,
    unit: undefined,
  };

  const isQualified =
    qualified !== undefined ? qualified : (variance?.value ?? 0) > 0;

  return (
    <Card
      sx={{
        height: '100%',
        border: '1px solid',
        borderColor: 'divider',
        position: 'relative',
        '&:hover': {
          boxShadow: 3,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          bgcolor: isQualified ? 'success.main' : 'error.main',
          color: 'primary.contrastText',
          px: 1,
          py: 0.5,
          borderRadius: 1,
          fontSize: '0.75rem',
        }}
      >
        {isQualified ? 'Qualified' : 'Not Qualified'}
      </Box>

      <Box
        sx={{
          p: 1.5,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box display="flex" gap={1} alignItems="center" mb={1}>
          <Icon sx={{ fontSize: ICON_SIZE, color: 'primary.main' }} />
          <Box>
            <Typography variant="subtitle1" fontWeight={500}>
              {title}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {ring.value} {ring.unit} ring
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 1 }} />

        <MultipleSimilarityCardTable similarities={subInsights} />

        <Box
          sx={{
            bgcolor: 'background.default',
            p: 1,
            borderRadius: 1,
          }}
        >
          <Typography
            variant="caption"
            color="text.primary"
            fontWeight={500}
            display="block"
          >
            {similarityObject.title}
          </Typography>
          <Typography variant="body2">
            {similarityObject.unit === '$'
              ? `$${formatValue({ value: similarityObject.value, valueType: similarityObject.valueType })}`
              : `${formatValue({ value: similarityObject.value, valueType: similarityObject.valueType })} ${similarityObject.unit || ''}`}
          </Typography>
        </Box>

        <Divider sx={{ my: 1, marginTop: 'auto' }} />

        <Box
          display="flex"
          alignItems="center"
          sx={{
            bgcolor: 'background.default',
            p: 1,
            borderRadius: 1,
          }}
        >
          {variance.value > 0 ? (
            <TrendingUp
              color="success"
              fontSize="small"
              sx={{ fontWeight: 'bold' }}
            />
          ) : (
            <TrendingDown
              color="error"
              fontSize="small"
              sx={{ fontWeight: 'bold' }}
            />
          )}
          <Typography variant="caption" fontWeight="bold">
            {variance.unit === '$'
              ? `$${formatValue({ value: variance.value })}`
              : `${formatValue({ value: variance.value })} ${variance.unit}`}
            (
            {formatValue({
              value: variance.percentage / 100,
              valueType: 'percentage',
            })}
            )
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
