import {
  CustomTerritory,
  YCustomTerritory,
} from '@plotr/plotr-multiplayer-data';
import { useMultiplayer } from '@plotr/react-multiplayer';
import { useMemo } from 'react';
import useDynamicMapStore from './useDynamicMapStore';

export default function useCustomTerritories() {
  const {
    hiddenTerritoryGroups,
    toggleAllTerritoryGroupsVisibility,
  } = useDynamicMapStore((state) => ({
    hiddenTerritoryGroups: state.hiddenTerritoryGroups,
    toggleAllTerritoryGroupsVisibility:
      state.toggleAllTerritoryGroupsVisibility,
  }));

  const customTerritories = useMultiplayer.State<CustomTerritory[]>((ydoc) => {
    const territories =
      ydoc
        ?.getArray<YCustomTerritory>('customTerritories')
        ?.toArray()
        .map((v) => v.toJSON() as CustomTerritory) ?? [];

    // Sort territories by label within each group
    return territories.sort((a, b) => {
      // First sort by group
      const groupCompare = (a.group || '').localeCompare(b.group || '');
      if (groupCompare !== 0) return groupCompare;

      // Then by label within group
      return (a.label || '').localeCompare(b.label || '');
    });
  });

  const territoryGroups = useMemo(() => {
    const groups = new Map<string, number>();
    customTerritories.forEach((territory: CustomTerritory) => {
      groups.set(territory.group, (groups.get(territory.group) || 0) + 1);
    });
    return Array.from(groups.entries()).sort((a, b) =>
      a[0].localeCompare(b[0])
    );
  }, [customTerritories]);

  const territoriesEnabled =
    territoryGroups.length > hiddenTerritoryGroups.length;

  const toggleAllTerritoriesVisibility = () =>
    toggleAllTerritoryGroupsVisibility(territoryGroups.map(([group]) => group));

  return {
    customTerritories,
    territoryGroups,
    territoriesEnabled,
    toggleAllTerritoriesVisibility,
  };
}
