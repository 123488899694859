import {
  DeviceHub as DeviceHubIcon,
  LocationOn as LocationOnIcon,
  MonetizationOn as MonetizationOnIcon,
  People as PeopleIcon,
} from '@mui/icons-material';
import { BoundaryType } from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';

export enum SimilarityInsightType {
  COMPETITORS = 'competitors',
  GENERATORS = 'generators',
  DEMOGRAPHICS = 'demographics',
  TRAFFIC = 'traffic',
  INCOME = 'income',
}

export const SimilarityInsightTypeIcon = {
  [SimilarityInsightType.DEMOGRAPHICS]: PeopleIcon,
  [SimilarityInsightType.TRAFFIC]: DeviceHubIcon,
  [SimilarityInsightType.INCOME]: MonetizationOnIcon,
  [SimilarityInsightType.COMPETITORS]: LocationOnIcon,
  [SimilarityInsightType.GENERATORS]: LocationOnIcon,
};

export type SimilarityInsightValue = {
  title: string;
  value: number;
  valueType: 'absolute' | 'percentage';
  unit?: string;
};

export type SimilarityInsightVariance = {
  value: number;
  percentage: number;
  unit: string;
};

export type SimilarityInsight = {
  title: string;
  evaluatedValue: SimilarityInsightValue;
  benchmarkValue: SimilarityInsightValue;
  variance: SimilarityInsightVariance;
  similarity: number;
};

export type ComposedSimilarityInsight = {
  title: string;
  subInsights: {
    title: string;
    evaluatedValue: SimilarityInsightValue;
    benchmarkValue?: SimilarityInsightValue;
    variance: SimilarityInsightVariance;
    similarity: number;
  }[];
  variance: SimilarityInsightVariance;
  similarity: number;
};

export type SimilarityData = {
  evaluatedLocation: {
    latitude: number;
    longitude: number;
  };
  insights: Record<
    SimilarityInsightType,
    Array<ComposedSimilarityInsight | SimilarityInsight>
  >;
};

export type CompetitorSetting = {
  brands: { brandId: string; website: string }[];
  queries: {
    id: string;
    name: string;
    query: { [key: string]: { path: string; value: string | string[] } }[];
  }[];
};

export interface BenchmarkLocation {
  latitude: number;
  longitude: number;
  name: string;
}

export type SimilarityReportParams = {
  evaluatedLocation: {
    latitude: number;
    longitude: number;
  };
  boundaryParams: {
    type: BoundaryType;
    value: number;
    tam: Record<
      'male' | 'female',
      {
        ageRanges: [number, number][];
      }
    >;
    tamFilters: Array<{
      condition: {
        field: string;
        operator: string;
        value: string[];
      };
    }>;
  };
  searchQuery?: {
    brandId?: string;
    searchString?: string;
    naicsCode?: string;
  };
  benchmarkLocations?: BenchmarkLocation[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
  userSettings?: {
    generators: CompetitorSetting;
    competitors: CompetitorSetting;
  };
};
