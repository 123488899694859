import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { create } from 'zustand';
import { PRICING_LAYERS } from '../../../features/pricing/constants/pricingLayers';
import * as PricingDataService from '../services/pricingDataService';

export interface PricingData {
  brand: string;
  data: FeatureCollection<Geometry, GeoJsonProperties>;
  color: string;
}

export interface PricingDataState {
  pricingData: PricingData[];
  hiddenBrands: string[];
  selectedBrands: string[];
  showLabels: boolean;
  error: Error | null;
  isLoading: boolean;
}

export interface PricingDataActions {
  fetchPricingData: (client: string) => Promise<void>;
  toggleBrandVisibility: (brand: string) => void;
  toggleBrand: (brand: string) => void;
  toggleColor: (brand: string, color: string) => void;
  toggleLabels: () => void;
}

const usePricingDataStore = create<PricingDataState & PricingDataActions>(
  (set) => ({
    pricingData: [],
    hiddenBrands: Object.values(PRICING_LAYERS).map((layer) => layer.id),
    selectedBrands: [],
    showLabels: false,
    error: null,
    isLoading: false,

    initializePricingLayers: () => {
      const initialLayers = Object.values(PRICING_LAYERS).map((layer) => ({
        brand: layer.id,
        data: {
          type: 'FeatureCollection' as const,
          features: [],
        },
        color: layer.color,
      }));

      set({ pricingData: initialLayers });
    },

    fetchPricingData: async (client: string) => {
      set({ isLoading: true, error: null });
      try {
        const layerData = await Promise.all(
          Object.values(PRICING_LAYERS).map((layer) =>
            PricingDataService.fetchPricingDataFromFolder(
              client,
              layer.endpoint
            )
          )
        );

        const pricingData = Object.values(PRICING_LAYERS).map(
          (layer, index) => ({
            brand: layer.id,
            data: layerData[index],
            color: layer.color,
          })
        );

        set({ pricingData, isLoading: false });
      } catch (error) {
        set({ error: error as Error, isLoading: false });
      }
    },

    toggleBrandVisibility: (brand: string) => {
      set((state) => ({
        hiddenBrands: state.hiddenBrands.includes(brand)
          ? state.hiddenBrands.filter((b) => b !== brand)
          : [...state.hiddenBrands, brand],
      }));
    },

    toggleColor: (brand: string, color: string) => {
      set((state) => ({
        pricingData: state.pricingData.map((p) =>
          p.brand === brand ? { ...p, color } : p
        ),
      }));
    },

    toggleLabels: () => set((state) => ({ showLabels: !state.showLabels })),

    toggleBrand: (brand: string) =>
      set((state) => ({
        selectedBrands: state.selectedBrands.includes(brand)
          ? state.selectedBrands.filter((b) => b !== brand)
          : [...state.selectedBrands, brand],
      })),
  })
);

export default usePricingDataStore;
