import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  LockableButton,
  LockIcon,
} from '~/src/common/components/LockableButton';
import useLayersStore from '~/src/features/dynamic-map/hooks/useLayersStore';
import { useSubscriptionDialog } from '~/src/global/components/SubscriptionDialog';
import { PsychographicDetailsModal } from './PsychographicDetailsModal';
import { PsychographicData } from './usePsychographicSearch';

export const PsychographicsTopTable = ({
  data,
  isLoading,
  isPermissionGranted,
}: {
  data: PsychographicData;
  isLoading: boolean;
  isPermissionGranted: boolean;
}) => {
  const total = data.reduce((acc, curr) => acc + Number(curr.count), 0);
  const [psychographicTypeId, setPsychographicTypeId] = useState<number | null>(
    null
  );

  const setHoverFilter = useLayersStore((state) => state.setHoverFilter);

  const handleMouseEnter = (name: string) => {
    setHoverFilter(['==', ['get', 'ls_1'], name]);
  };

  const handleMouseLeave = () => {
    setHoverFilter(null);
  };

  const { open: openSubscriptionDialog } = useSubscriptionDialog();

  return (
    <>
      {data.length === 0 || isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={8}>
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h6" component="h2">
                  Top 10 Psychographics
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isPermissionGranted ? (
              data?.map(({ id, name, count, parentColor }, index) => (
                <TableRow
                  key={id}
                  onClick={() =>
                    name?.toLowerCase() !== 'undefined' &&
                    setPsychographicTypeId(id)
                  }
                  onMouseEnter={() => handleMouseEnter(name)}
                  onMouseLeave={() => handleMouseLeave()}
                  sx={{
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: `#${parentColor}70`,
                    },
                  }}
                >
                  <TableCell
                    sx={{ backgroundColor: `#${parentColor}` }}
                    width={8}
                  />
                  <TableCell>
                    <Typography variant="body2" component="p">
                      {index + 1}. {name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      component="p"
                      fontWeight={'bold'}
                      textAlign={'right'}
                    >
                      {Number(count).toLocaleString()} (
                      {Math.round((Number(count) / total) * 100)}
                      %)
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <LockableButton isLocked={true} onClick={openSubscriptionDialog}>
                <TableRow>
                  <TableCell colSpan={1}>
                    <LockIcon active={false} styles={{ marginLeft: 'auto' }} />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Typography variant="body2" component="p">
                      You do not have permission to view this data.
                    </Typography>
                  </TableCell>
                </TableRow>
              </LockableButton>
            )}
          </TableBody>
        </Table>
      )}
      <PsychographicDetailsModal
        setPsychographicTypeId={setPsychographicTypeId}
        psychographicTypeId={psychographicTypeId}
      />
    </>
  );
};
