import { Expression } from 'mapbox-gl';
import { useEffect } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';
import useLayerIds from '../../../dynamic-map/hooks/useLayerIds';
import useMapContext from '../../../dynamic-map/hooks/useMapContext';

interface PricingItem {
  item_group?: { Value: string };
  sub_category?: { Value: string };
  predicted_price?: { Value: number };
  tier_range?: { Value: string };
  national_index_group?: { Value: number };
  market_index_group?: { Value: number };
  category?: { Value: string };
}

export interface FormattedTerritoryData {
  territory: string;
  market: string;
  urbanicityType: string;
  radius: number;
  taId: string | number;
  address: string;
  coordinates: string;
  competitors: string;
  pulseTier: number;
  avgNationalIndex: number;
  avgMarketIndex: number;
  datesCollected: string;
  offerings: Record<
    string,
    Record<string, { name: string; price: number | null; tierRange: string }>
  >;
  popupCoordinates: {
    longitude: number;
    latitude: number;
  };
}

interface PulsePriceTerritorySourceProps {
  data: GeoJSON.FeatureCollection;
  showLabels: boolean;
  onTerritoryClick?: (territory: FormattedTerritoryData) => void;
}

const PulsePriceTerritorySource: React.FC<PulsePriceTerritorySourceProps> = ({
  data,
  showLabels,
  onTerritoryClick,
}: PulsePriceTerritorySourceProps) => {
  const map = useMapContext();
  const roadsLayerId = useLayerIds((layer) => layer.id === 'roads-layer')[0];
  const layerId = 'territory-pricing-layer';

  function transformTerritoryData(
    feature: GeoJSON.Feature,
    clickCoords: { lng: number; lat: number }
  ): FormattedTerritoryData {
    const p = feature.properties;
    if (!p) throw new Error('No properties found in feature');

    // Parse the data if it's a string
    let dataArray;
    try {
      dataArray = typeof p.data === 'string' ? JSON.parse(p.data) : p.data;
    } catch (e) {
      console.error('Error parsing data:', e);
      dataArray = [];
    }

    // Group offerings by category
    const offerings = Array.isArray(dataArray)
      ? dataArray.reduce(
          (
            acc: Record<
              string,
              Record<
                string,
                { name: string; price: number | null; tierRange: string }
              >
            >,
            item: PricingItem
          ) => {
            const category = item.category?.Value || 'Uncategorized';
            const key = item.item_group?.Value || '';
            const name =
              item.sub_category?.Value || item.item_group?.Value || '';
            const price = item.predicted_price?.Value
              ? Math.round(item.predicted_price.Value)
              : null;
            const tierRange = item.tier_range?.Value || '';

            if (!category || !key) return acc;

            if (!acc[category]) {
              acc[category] = {};
            }
            acc[category][key] = { name, price, tierRange };
            return acc;
          },
          {}
        )
      : {};

    // Calculate average indices
    const avgNationalIndex = Array.isArray(dataArray)
      ? Math.round(
          dataArray.reduce(
            (acc, item) => acc + Number(item.national_index_group?.Value || 0),
            0
          ) / dataArray.length
        )
      : 0;

    const avgMarketIndex = Array.isArray(dataArray)
      ? Math.round(
          dataArray.reduce(
            (acc, item) => acc + Number(item.market_index_group?.Value || 0),
            0
          ) / dataArray.length
        )
      : 0;

    return {
      territory: p.territory || '',
      market: p.cbsa || '',
      urbanicityType: p.urbanization_type
        ? p.urbanization_type.charAt(0).toUpperCase() +
          p.urbanization_type.slice(1)
        : '',
      radius: p.radius || 0,
      taId: p.taid_id || '',
      address: p.address || '',
      coordinates: `${clickCoords.lat}, ${clickCoords.lng}`,
      competitors: p.competitors || '',
      pulseTier: p.pulse_tier || 0,
      avgNationalIndex,
      avgMarketIndex,
      datesCollected: p.dates_collected || '',
      offerings,
      popupCoordinates: {
        longitude: clickCoords.lng,
        latitude: clickCoords.lat,
      },
    };
  }

  useEffect(() => {
    if (!map) return;

    const handleClick = (event: MapLayerMouseEvent) => {
      event.preventDefault();
      const feature = event.features?.[0];
      if (feature) {
        const territory = transformTerritoryData(feature, event.lngLat);
        onTerritoryClick?.(territory);
      }
    };

    map.on('click', layerId, handleClick);
    return () => {
      map.off('click', layerId, handleClick);
    };
  }, [map, layerId, onTerritoryClick]);

  return (
    <Source id="territory-pricing-source" type="geojson" data={data}>
      {showLabels && (
        <Layer
          id={`${layerId}-label`}
          type="symbol"
          layout={{
            'text-field': ['get', 'territory'] as Expression,
            'text-font': ['Arial Unicode MS Regular'],
            'text-size': 16,
            'text-allow-overlap': true,
          }}
          paint={{
            'text-color': 'black',
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 2,
          }}
          beforeId={roadsLayerId}
        />
      )}
      <Layer
        id={layerId}
        type="fill"
        paint={{
          'fill-color': [
            'case',
            ['==', ['get', 'pulse_tier'], 1],
            'rgba(255, 0, 0, 0.5)',
            ['==', ['get', 'pulse_tier'], 2],
            'rgba(255, 255, 0, 0.5)',
            ['==', ['get', 'pulse_tier'], 3],
            'rgba(0, 255, 0, 0.5)',
            'rgba(128, 128, 128, 0.5)',
          ],
          'fill-opacity': 0.8,
          'fill-outline-color': '#FF4B4B',
        }}
        beforeId={showLabels ? `${layerId}-label` : roadsLayerId}
      />
    </Source>
  );
};

export default PulsePriceTerritorySource;
