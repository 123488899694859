import { Expression } from 'mapbox-gl';
import { useEffect } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';
import getPinURL from '~/src/common/helpers/getPinURL';
import {
  defaultIconSize,
  defaultZoomStops,
  exponentialIconRatio,
} from '../../../dynamic-map/components/POISource/POIHelpers';
import useLayerIds from '../../../dynamic-map/hooks/useLayerIds';
import useMapContext from '../../../dynamic-map/hooks/useMapContext';
import useMapImage from '../../../dynamic-map/hooks/useMapImage';

interface PricingItem {
  semantic_key?: { Value: string };
  predicted_price?: { Value: number };
  item_group?: { Value: string };
  category?: { Value: string };
  sub_category?: { Value: string };
  national_index_group?: { Value: number };
  market_index_group?: { Value: number };
  tier_range?: { Value: string };
  brand?: { Value: string };
}

export interface FormattedTAIDData {
  territory: string;
  cbsa: string;
  urbanizationType: string;
  radius: number;
  taId: number;
  address: string;
  coordinates: string;
  competitors: string;
  datesCollected: string;
  avgNationalIndex: number;
  avgMarketIndex: number;
  pulseTier: number;
  offerings: Record<
    string,
    Record<string, { name: string; price: number | null; tierRange: string }>
  >;
  popupCoordinates: {
    longitude: number;
    latitude: number;
  };
}

interface PricingTAIDSourceProps {
  sourceId?: string;
  layerId?: string;
  data: GeoJSON.FeatureCollection;
  showLabels: boolean;
  onPricingClick?: (location: FormattedTAIDData) => void;
}

function transformTAIDData(
  feature: GeoJSON.Feature,
  clickCoords: { lng: number; lat: number }
): FormattedTAIDData {
  const p = feature.properties;
  if (!p) throw new Error('No properties found in feature');

  const dataArray = typeof p.data === 'string' ? JSON.parse(p.data) : p.data;
  // Group offerings by category
  const offerings = Array.isArray(dataArray)
    ? dataArray.reduce(
        (
          acc: Record<
            string,
            Record<
              string,
              { name: string; price: number | null; tierRange: string }
            >
          >,
          item: PricingItem
        ) => {
          const category = item.category?.Value;
          const key = item.item_group?.Value;
          const name = item.sub_category?.Value || item.item_group?.Value || '';
          const price = item.predicted_price?.Value
            ? Math.round(item.predicted_price.Value)
            : null;
          const tierRange = item.tier_range?.Value || '';

          if (category && key) {
            if (!acc[category]) {
              acc[category] = {};
            }
            acc[category][key] = { name, price, tierRange };
          }
          return acc;
        },
        {}
      )
    : {};

  // Calculate average indices
  const allItems = Array.from(Object.values(dataArray)).filter(
    (item): item is PricingItem =>
      item !== null && typeof item === 'object' && 'predicted_price' in item
  );

  const avgNationalIndex =
    allItems.length > 0
      ? Math.round(
          allItems.reduce(
            (acc, item) => acc + Number(item.national_index_group?.Value || 0),
            0
          ) / allItems.length
        )
      : 0;

  const avgMarketIndex =
    allItems.length > 0
      ? Math.round(
          allItems.reduce(
            (acc, item) => acc + Number(item.market_index_group?.Value || 0),
            0
          ) / allItems.length
        )
      : 0;

  // Get coordinates from geometry
  const coordinates =
    feature.geometry.type === 'Point'
      ? `${feature.geometry.coordinates[1]}, ${feature.geometry.coordinates[0]}`
      : '';

  // Capitalize first letter of urbanicity
  const urbanicity = p.urbanization_type
    ? p.urbanization_type.charAt(0).toUpperCase() + p.urbanization_type.slice(1)
    : '';

  return {
    territory: p.territory || '',
    cbsa: p.cbsa || '',
    urbanizationType: urbanicity,
    radius: p.radius || 0,
    taId: p.taid_id || 0,
    address: p.address || '',
    coordinates,
    competitors: p.competitors || '',
    datesCollected: p.dates_collected || '',
    avgNationalIndex,
    avgMarketIndex,
    pulseTier: p.pulse_tier || 0,
    offerings,
    popupCoordinates: {
      longitude: clickCoords.lng,
      latitude: clickCoords.lat,
    },
  };
}

const PulsePriceTAIDSource: React.FC<PricingTAIDSourceProps> = ({
  sourceId = 'taid-pvolve-pricing-source',
  layerId = 'taid-pvolve-pricing-layer',
  data,
  showLabels,
  onPricingClick,
}: PricingTAIDSourceProps) => {
  const map = useMapContext();
  const roadsLayerId = useLayerIds((layer) => layer.id === 'roads-layer')[0];

  // Create pin URLs for each tier
  const tier1PinURL = getPinURL({
    color: 'rgba(255, 0, 0, 1)', // Red for tier 1
    background: '#FFFFFF',
  });
  const tier2PinURL = getPinURL({
    color: 'rgba(255, 255, 0, 1)', // Yellow for tier 2
    background: '#FFFFFF',
  });
  const tier3PinURL = getPinURL({
    color: 'rgba(0, 255, 0, 1)', // Green for tier 3
    background: '#FFFFFF',
  });
  const defaultPinURL = getPinURL({
    color: 'rgba(128, 128, 128, 1)', // Gray for default
    background: '#FFFFFF',
  });

  // Load all pin images
  const isTier1Ready = useMapImage({
    id: `${layerId}-tier1`,
    src: tier1PinURL,
  });
  const isTier2Ready = useMapImage({
    id: `${layerId}-tier2`,
    src: tier2PinURL,
  });
  const isTier3Ready = useMapImage({
    id: `${layerId}-tier3`,
    src: tier3PinURL,
  });
  const isDefaultReady = useMapImage({
    id: `${layerId}-default`,
    src: defaultPinURL,
  });

  // Only proceed when all images are loaded
  const areImagesReady =
    isTier1Ready && isTier2Ready && isTier3Ready && isDefaultReady;

  // Create expression for icon-image based on tier
  const iconImageExpression: Expression = [
    'match',
    ['get', 'pulse_tier'],
    1,
    `${layerId}-tier1`,
    2,
    `${layerId}-tier2`,
    3,
    `${layerId}-tier3`,
    `${layerId}-default`,
  ];

  useEffect(() => {
    if (!map) return;

    const handleClick = (event: MapLayerMouseEvent) => {
      event.preventDefault();
      const feature = event.features?.[0];
      if (feature?.geometry?.type === 'Point' && feature.properties) {
        const formattedLocation = transformTAIDData(feature, event.lngLat);
        onPricingClick?.(formattedLocation);
      }
    };

    map.on('click', layerId, handleClick);
    return () => {
      map.off('click', layerId, handleClick);
    };
  }, [map, layerId, onPricingClick]);

  return (
    <Source id={sourceId} type="geojson" data={data}>
      <Layer
        id={layerId}
        type="symbol"
        layout={{
          'icon-image': areImagesReady ? iconImageExpression : '',
          'icon-anchor': 'bottom',
          'icon-allow-overlap': true,
          'icon-size': {
            type: 'exponential',
            stops: [
              [defaultZoomStops[0], defaultIconSize * exponentialIconRatio],
              [defaultZoomStops[1], defaultIconSize],
            ],
          },
          ...(showLabels && {
            'text-field': ['get', 'taid_id'] as Expression,
            'text-offset': [0, 0.5],
            'text-anchor': 'top',
            'text-size': 15,
            'text-allow-overlap': true,
          }),
        }}
        beforeId={roadsLayerId}
      />
    </Source>
  );
};

export default PulsePriceTAIDSource;
