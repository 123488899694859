import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Popup } from 'react-map-gl';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import { FormattedPriceMatchingData } from './PulsePriceMatchingSource';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`price-matching-tabpanel-${index}`}
      aria-labelledby={`price-matching-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

interface PricingPopupProps {
  data: FormattedPriceMatchingData;
  onClose: () => void;
}

const formatCurrency = (value: number | null) => {
  if (value === null) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  }).format(value);
};

const formatValue = (value: string | number | null | undefined) => {
  if (value === null || value === undefined || value === '' || value === 0) {
    return '-';
  }
  return value;
};

const PricingPopup: React.FC<PricingPopupProps> = ({
  data,
  onClose,
}: PricingPopupProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    false
  );
  const map = useMapContext();
  const { userSettings } = useSettingsStore();
  const client = userSettings?.client;

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleAccordionChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  // Group offerings by category
  const groupedOfferings = data.offerings.reduce<
    Record<string, typeof data.offerings>
  >((acc, offering) => {
    const category = offering.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(offering);
    return acc;
  }, {});

  const renderLocationInfo = () => {
    const locationFields = [{ label: 'Market', value: data.cbsa }];

    return (
      <Table size="small">
        <TableBody>
          {locationFields.map(({ label, value }, index) => (
            <TableRow key={index}>
              <TableCell
                component="th"
                sx={{
                  pl: 0,
                  width: '150px',
                  fontWeight: 'bold',
                }}
              >
                {label}
              </TableCell>
              <TableCell sx={{ pl: 2 }}>{formatValue(value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderPricingInfo = () => {
    return (
      <>
        {Object.entries(groupedOfferings)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([category, offerings]) => (
            <Accordion
              key={category}
              expanded={expandedAccordion === category}
              onChange={handleAccordionChange(category)}
              sx={{
                '&:before': { display: 'none' },
                boxShadow: 'none',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  px: 1,
                  '&.Mui-expanded': {
                    minHeight: '48px',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: '12px 0',
                  },
                }}
              >
                <Typography variant="subtitle2">
                  {category} ({offerings.length} items)
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Table size="small" sx={{ '& td, & th': { px: 1 } }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>
                        Subcategory
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', width: '15%' }}>
                        Competitor Price
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', width: '15%' }}>
                        Current Price
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {offerings
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((offering, index) => (
                        <TableRow key={index}>
                          <TableCell>{offering.name}</TableCell>
                          <TableCell>
                            {formatCurrency(offering.competitor_price)}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(offering.price)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
      </>
    );
  };

  const mapHeight = map?.getContainer()?.clientHeight;
  const halfMapHeight = mapHeight ? mapHeight / 2 : 0;

  return (
    <Popup
      latitude={data.popupCoordinates.latitude}
      longitude={data.popupCoordinates.longitude}
      closeButton={false}
      closeOnClick={false}
      maxWidth="800px"
    >
      <Card
        sx={{
          maxWidth: 800,
          boxShadow: 'none',
          maxHeight: halfMapHeight,
          overflow: 'auto',
        }}
      >
        <CardContent sx={{ padding: 1, position: 'relative' }}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              padding: '4px',
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              mb: 2,
              pr: 3,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Box sx={{ mr: 4 }}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {data.competitor}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ mb: 0.5, display: 'block' }}
                  >
                    {data.address}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ display: 'block', mb: 1 }}
                  >
                    {data.coordinates}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                      minHeight: 36,
                      '& .MuiTab-root': {
                        minHeight: 36,
                        padding: '6px 12px',
                        fontSize: '0.875rem',
                      },
                    }}
                  >
                    <Tab label="Pulse Price" />
                    <Tab label="Location Info" />
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Box>

          <TabPanel value={tabValue} index={0}>
            {renderPricingInfo()}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {renderLocationInfo()}
          </TabPanel>
        </CardContent>
      </Card>
    </Popup>
  );
};

export default PricingPopup;
