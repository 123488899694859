import {
  ExpandMore as ExpandMoreIcon,
  TextFields,
  TextFieldsOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import usePricingDataStore from '../../dynamic-map/hooks/usePricingDataStore';
import { PRICING_LAYERS } from '../constants/pricingLayers';
import { extractUniqueCompetitors } from '../layers/pulsePriceMatching/PulsePriceMatchingSource';

const PricingDataMenu = () => {
  const {
    pricingData,
    hiddenBrands,
    selectedBrands,
    toggleBrandVisibility,
    toggleBrand,
    showLabels,
    toggleLabels,
  } = usePricingDataStore();

  const competitorsLayer = pricingData.find(
    (layer) => layer.brand === PRICING_LAYERS.MATCHING.id
  );

  const competitors = competitorsLayer
    ? extractUniqueCompetitors(competitorsLayer.data)
    : [];

  const showLayers = Object.values(PRICING_LAYERS).some(
    (layer) => !hiddenBrands.includes(layer.id)
  );

  const handleCompetitorClick = (competitorId: string) => {
    // Toggle the competitor selection
    toggleBrand(competitorId);

    // If we're selecting a competitor and the matching layer is hidden,
    // make it visible by removing it from hiddenBrands
    if (
      !selectedBrands.includes(competitorId) &&
      hiddenBrands.includes(PRICING_LAYERS.MATCHING.id)
    ) {
      toggleBrandVisibility(PRICING_LAYERS.MATCHING.id);
    }
  };

  return (
    <Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={-1}
      >
        <Typography variant="h6" marginRight={1}>
          Pulse Price Layers
        </Typography>
        <Box display="flex" alignItems="center">
          <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />
          <Box display="flex" alignItems="center" gap={1}>
            <Tooltip title={showLayers ? 'Hide All Layers' : 'Show All Layers'}>
              <IconButton
                onClick={() => {
                  Object.values(PRICING_LAYERS).forEach((layer) => {
                    if (showLayers) {
                      if (!hiddenBrands.includes(layer.id)) {
                        toggleBrandVisibility(layer.id);
                      }
                    } else {
                      if (hiddenBrands.includes(layer.id)) {
                        toggleBrandVisibility(layer.id);
                      }
                    }
                  });
                }}
                size="small"
                color={showLayers ? 'primary' : 'default'}
              >
                {showLayers ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
            <Tooltip title={showLabels ? 'Hide Labels' : 'Show Labels'}>
              <IconButton
                onClick={toggleLabels}
                size="small"
                color={showLabels ? 'primary' : 'default'}
              >
                {showLabels ? <TextFields /> : <TextFieldsOutlined />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />

      {Object.values(PRICING_LAYERS).map((layer) => {
        const isMatchingLayer = layer.id === PRICING_LAYERS.MATCHING.id;

        return isMatchingLayer ? (
          <Box key={layer.id}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  pr={2}
                >
                  <Typography>{layer.displayName}</Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleBrandVisibility(layer.id);
                    }}
                    size="small"
                    color={
                      !hiddenBrands.includes(layer.id) ? 'primary' : 'default'
                    }
                  >
                    {hiddenBrands.includes(layer.id) ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexWrap="wrap" gap={0.5}>
                  {competitors.map((competitor) => (
                    <Chip
                      key={competitor.id}
                      label={competitor.name}
                      clickable
                      onClick={() => handleCompetitorClick(competitor.id)}
                      color={
                        selectedBrands.includes(competitor.id)
                          ? 'primary'
                          : 'default'
                      }
                      sx={{ margin: '0.2rem' }}
                    />
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        ) : (
          <Box
            key={layer.id}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            sx={{
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)',
              },
              borderRadius: 1,
              p: 1,
            }}
          >
            <Typography variant="body1" color="textSecondary">
              {layer.displayName}
            </Typography>
            <IconButton
              onClick={() => toggleBrandVisibility(layer.id)}
              size="small"
              color={!hiddenBrands.includes(layer.id) ? 'primary' : 'default'}
            >
              {hiddenBrands.includes(layer.id) ? (
                <VisibilityOff />
              ) : (
                <Visibility />
              )}
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};

export default PricingDataMenu;
