import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import loading from '~/src/assets/animations/logo-loop.json';
import { PulseReportConfig } from '~/src/constants';
import { formatValue } from '~/src/global/helpers/formatValue';
import LogoAnimation from '../animations/LogoAnimation';
import { MultipleSimilarityCard } from './MultipleSimilarityCard';
import { SingleSimilarityCard } from './SingleSimilarityCard';
import { SimilarityData } from './types';

interface CustomerSummaryProps {
  totalSimilarity: number;
  similarities: SimilarityData[];
  onLoad?: () => void;
  errorMessage?: string;
  isLoading: boolean;
}

const { LOADING_LABEL } = PulseReportConfig;

export function CustomerSummary({
  totalSimilarity,
  similarities,
  onLoad,
  errorMessage,
  isLoading,
}: CustomerSummaryProps) {
  console.log('similarities', similarities);
  useEffect(() => {
    if (totalSimilarity !== undefined && similarities?.length > 0 && onLoad) {
      onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSimilarity]);

  // Calculate number of "green flags" (positive indicators)
  const greenFlags = similarities.filter((similarity) =>
    similarity.qualified !== undefined
      ? similarity.qualified
      : similarity.variance.percentage >= 0
  ).length;
  const redFlags = similarities.length - greenFlags;

  const isQualified = greenFlags > similarities.length / 2;

  const qualificationText = `This site is ${
    isQualified ? 'Qualified' : 'Not Qualified'
  } because ${isQualified ? greenFlags : redFlags} out of ${similarities.length} indicators ${
    isQualified ? 'meet or exceed' : 'do not meet or exceed'
  } benchmark values.`;

  return (
    <Box sx={{ padding: 2 }}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <Typography variant="h5" fontWeight={500} gutterBottom>
            Site Summary
          </Typography>
        </Box>

        {isLoading ? (
          <Box width="100%" display="flex" justifyContent="center">
            <LogoAnimation src={loading} label={LOADING_LABEL} />
          </Box>
        ) : errorMessage ? (
          <Typography>{errorMessage}</Typography>
        ) : (
          <>
            <Box
              sx={{
                bgcolor: isQualified ? 'success.main' : 'error.main',
                color: 'primary.contrastText',
                px: 2,
                py: 1.5,
                borderRadius: 1,
              }}
            >
              <Typography variant="h6" sx={{ textAlign: 'left' }}>
                {isQualified ? 'Qualified' : 'Not Qualified'}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, textAlign: 'left' }}>
                {qualificationText}
              </Typography>
            </Box>

            <Box
              sx={{
                bgcolor: '#95b6ed',
                color: 'primary.contrastText',
                px: 2,
                py: 1.5,
                borderRadius: 1,
                display: 'flex',
                gap: 2,
              }}
            >
              <Typography variant="h6">Total Similarity:</Typography>
              <Typography variant="h6">
                {formatValue({
                  value: totalSimilarity,
                  valueType: 'percentage',
                })}
              </Typography>
            </Box>

            <Grid data-section="insights" container spacing={2}>
              {similarities.map((similarity, index) => {
                console.log(similarity);
                const hasSubInsights = 'subInsights' in similarity;

                if (hasSubInsights) {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <MultipleSimilarityCard {...similarity} />
                    </Grid>
                  );
                }

                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <SingleSimilarityCard {...similarity} />
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
}
