import { useEffect, useState } from 'react';
import { MapLayerMouseEvent } from 'react-map-gl';
import useMapContext from '../../dynamic-map/hooks/useMapContext';
import usePricingDataStore from '../../dynamic-map/hooks/usePricingDataStore';
import FranchisePricingPopup from '../layers/pulsePriceFranchises/FranchisePricingPopup';
import PulsePriceFranchisesSource, {
  FormattedFranchiseData,
} from '../layers/pulsePriceFranchises/PulsePriceFranchisesSource';
import PricingPopup from '../layers/pulsePriceMatching/PricingPopup';
import PulsePriceMatchingSource, {
  FormattedPriceMatchingData,
} from '../layers/pulsePriceMatching/PulsePriceMatchingSource';
import PulsePriceTAIDSource, {
  FormattedTAIDData,
} from '../layers/pulsePriceTAID/PulsePriceTAIDSource';
import TAIDPricingPopup from '../layers/pulsePriceTAID/TAIDPricingPopup';
import PulsePriceTerritorySource, {
  FormattedTerritoryData,
} from '../layers/pulsePriceTerritory/PulsePriceTerritorySource';
import TerritoryPricingPopup from '../layers/pulsePriceTerritory/TerritoryPricingPopup';

type PopupData =
  | { type: 'price-matching'; data: FormattedPriceMatchingData }
  | { type: 'territory'; data: FormattedTerritoryData }
  | { type: 'taid'; data: FormattedTAIDData }
  | { type: 'franchise'; data: FormattedFranchiseData };

const PricingModelOutput = () => {
  const map = useMapContext();
  const { pricingData, hiddenBrands, showLabels } = usePricingDataStore();
  const [activePopup, setActivePopup] = useState<PopupData | null>(null);

  useEffect(() => {
    if (!map) return;

    const handleMapClick = (event: MapLayerMouseEvent) => {
      if (event.defaultPrevented) return;
      setActivePopup(null);
    };

    map.on('click', handleMapClick);
    return () => {
      map.off('click', handleMapClick);
    };
  }, [map]);

  const renderPopup = () => {
    if (!activePopup) return null;

    switch (activePopup.type) {
      case 'price-matching':
        return (
          <PricingPopup
            data={activePopup.data}
            onClose={() => setActivePopup(null)}
          />
        );
      case 'territory':
        return (
          <TerritoryPricingPopup
            data={activePopup.data}
            onClose={() => setActivePopup(null)}
          />
        );
      case 'taid':
        return (
          <TAIDPricingPopup
            data={activePopup.data}
            onClose={() => setActivePopup(null)}
          />
        );
      case 'franchise':
        return (
          <FranchisePricingPopup
            data={activePopup.data}
            onClose={() => setActivePopup(null)}
          />
        );
      default:
        return null;
    }
  };

  const matchingData = pricingData.find(
    (d) => d.brand === 'pulse-price-matching'
  )?.data;
  const territoryData = pricingData.find(
    (d) => d.brand === 'pulse-price-territory'
  )?.data;
  const taidData = pricingData.find(
    (d) => d.brand === 'pulse-price-taid'
  )?.data;
  const franchiseData = pricingData.find(
    (d) => d.brand === 'pulse-price-franchises'
  )?.data;

  return (
    <>
      {matchingData && !hiddenBrands.includes('pulse-price-matching') && (
        <PulsePriceMatchingSource
          data={matchingData}
          showLabels={showLabels}
          onPricingClick={(location) =>
            setActivePopup({ type: 'price-matching', data: location })
          }
        />
      )}

      {territoryData && !hiddenBrands.includes('pulse-price-territory') && (
        <PulsePriceTerritorySource
          data={territoryData}
          showLabels={showLabels}
          onTerritoryClick={(territory) =>
            setActivePopup({ type: 'territory', data: territory })
          }
        />
      )}

      {taidData && !hiddenBrands.includes('pulse-price-taid') && (
        <PulsePriceTAIDSource
          data={taidData}
          showLabels={showLabels}
          onPricingClick={(location) =>
            setActivePopup({ type: 'taid', data: location })
          }
        />
      )}

      {franchiseData && !hiddenBrands.includes('pulse-price-franchises') && (
        <PulsePriceFranchisesSource
          data={franchiseData}
          showLabels={showLabels}
          onFranchiseClick={(location) =>
            setActivePopup({ type: 'franchise', data: location })
          }
        />
      )}

      {renderPopup()}
    </>
  );
};

export default PricingModelOutput;
