import { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { CustomPin } from '@plotr/plotr-multiplayer-data';
import getPinURL from '~/src/common/helpers/getPinURL';
import useDynamicMapStore, {
  MapStyle,
} from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useRulesets from '~/src/features/dynamic-map/hooks/useRulesets';
import { TEXT_STYLES } from '../styles/constants';
import { calculatePinScale, getColor } from '../utils/pinHelpers';

import useSettingsStore from '~/src/global/hooks/useSettingsStore';

export function useCustomPinMarker(pin: CustomPin) {
  const rules = useRulesets();
  const userSettings = useSettingsStore((state) => state.userSettings);
  const zoom = useDynamicMapStore(useCallback((state) => state.zoomLevel, []));

  const { selectCustomPinId, mapStyle, pinLabelsEnabled } = useDynamicMapStore(
    useCallback(
      (state) => ({
        selectCustomPinId: state.selectCustomPinId,
        mapStyle: state.mapStyle,
        pinLabelsEnabled: state.pinLabelsEnabled,
      }),
      []
    ),
    shallow
  );

  const orgSiteURL = userSettings?.orgSiteURL;

  const pinColor = useMemo(
    () => getColor(rules, pin?.keyValuePairs ?? {}, pin?.tags ?? []),
    [rules, pin?.keyValuePairs, pin?.tags]
  );

  const pinURL = useMemo(
    () =>
      getPinURL({
        color: pin.group === 'Temporary' ? '#CCCCCC' : (pinColor ?? '#3FB1CE'),
        background: '#FFFFFF',
        website: orgSiteURL
          ? orgSiteURL
          : pin.group === 'Temporary'
            ? 'https://plotr.com'
            : undefined,
        iconRatio: orgSiteURL
          ? 0.7
          : pin.group === 'Temporary'
            ? 0.7
            : undefined,
      }),
    [pinColor, pin.group, orgSiteURL]
  );

  const scale = useMemo(() => calculatePinScale(zoom), [zoom]);

  const textStyle = useMemo(
    () => ({
      ...(mapStyle === MapStyle.Satellite || mapStyle === MapStyle.Dark
        ? TEXT_STYLES.dark
        : TEXT_STYLES.light),
      opacity: zoom ? (zoom <= 10 ? Math.max(0, (zoom - 5) / 5) : 1) : 1,
      transition: 'opacity 0.2s ease-in-out',
      marginTop: '0.25rem',
      textAlign: 'center' as const,
      visibility: pinLabelsEnabled ? ('visible' as const) : ('hidden' as const),
      fontSize: '2rem',
    }),
    [mapStyle, zoom, pinLabelsEnabled]
  );

  const handlePinClick = useCallback(() => {
    if (pin.group !== 'Temporary') {
      selectCustomPinId(pin.id);
    }
  }, [selectCustomPinId, pin.id]);

  return {
    pinURL,
    scale,
    textStyle,
    handlePinClick,
  };
}
