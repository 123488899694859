import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { FetchTradeAreaOverlapDataResult } from '../../dynamic-map/services/poiService';

interface MobileOverlapStore {
  mobileOverlapData: FetchTradeAreaOverlapDataResult | null;
  setMobileOverlapData: (data: FetchTradeAreaOverlapDataResult | null) => void;

  mobileOverlapDataIsLoading: boolean;
  setMobileOverlapDataIsLoading: (isLoading: boolean) => void;

  mobileOverlapDataError: string | null;
  setMobileOverlapDataError: (error: string | null) => void;

  mobileOverlapDataCache: Record<string, FetchTradeAreaOverlapDataResult>;
  setMobileOverlapDataCache: (
    key: string,
    data: FetchTradeAreaOverlapDataResult
  ) => void;
}

const useMobileOverlapStore = create(
  persist(
    subscribeWithSelector<MobileOverlapStore>((set) => ({
      mobileOverlapData: null,
      setMobileOverlapData: (data: FetchTradeAreaOverlapDataResult | null) =>
        set(() => ({ mobileOverlapData: data })),

      mobileOverlapDataIsLoading: false,
      setMobileOverlapDataIsLoading: (isLoading: boolean) =>
        set(() => ({ mobileOverlapDataIsLoading: isLoading })),

      mobileOverlapDataError: null,
      setMobileOverlapDataError: (error: string | null) =>
        set(() => ({ mobileOverlapDataError: error })),

      mobileOverlapDataCache: {},
      setMobileOverlapDataCache: (
        key: string,
        data: FetchTradeAreaOverlapDataResult
      ) =>
        set((state) => {
          const currentEntityId = key.split(':')[0];
          const newCache: Record<string, FetchTradeAreaOverlapDataResult> = {};

          Object.entries(state.mobileOverlapDataCache).forEach(([k, v]) => {
            if (k.startsWith(currentEntityId)) {
              newCache[k] = v;
            }
          });

          return {
            mobileOverlapDataCache: {
              ...newCache,
              [key]: data,
            },
          };
        }),
    })),
    {
      name: 'mobile-overlap-state',
    }
  )
);

export default useMobileOverlapStore;
